import React, { useEffect, useState } from 'react';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Avatar7 from './../../assets/images/avatar7.png';
import styles from './UsersComponent.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import { userService } from '../../Services/userServices';

const ViewUserComponent = (props) => {
    const { history } = props
    const [userData, setUserData] = useState([])

    useEffect(() => {
        setUserData(props.location.state.details)
    }, [props, userData])

    const handleEdit = (_id) => {
        userService.httpApi('GET', `admin/users/${_id}`, null)
            .then(res => {
                props.history.push({
                    pathname: '/users/edit',
                    search: '',
                    state: { details: res }
                });
            })
            .catch(err => console.log(err))
    }

    const goBack = () => {
        history.goBack()
    }

    return (
        <div className={styles.AddUserComponent} >

            <div className={styles.LoginComponent}>
                <Container>
                    <Row>
                        <Col className="p-3 mt-5 form-outer">
                            <Row className="border-bottom">
                                <Col>
                                    <div className="from-heading">
                                        <h2>User details</h2>
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        className="float-right"
                                        onClick={goBack}
                                        variant="primary"
                                    >
                                        <ArrowBackIosIcon />
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                            <div className="row gutters-sm">
                                <div className="col-md-4 mb-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-column align-items-center text-center">
                                                <img src={Avatar7} alt="Admin" className="rounded-circle" width="150" />
                                                <div className="mt-3">
                                                    <h4>{`${userData.firstName} ${userData.lastName}`}</h4>
                                                    <p className="text-secondary mb-1">Royal Stack Developer</p>
                                                    <p className="text-muted font-size-sm">Bay Area, San Francisco, CA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0">Full Name</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    {`${userData.firstName} ${userData.lastName}`}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0">Email</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    {`${userData.email}`}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0">Phone</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    {`${userData.phone}`}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0">Status</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary text-capitalize">
                                                    {`${userData.status}`}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0">User Type</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    {`${userData.userType}`}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Button
                                                        onClick={() => handleEdit(userData._id)}
                                                        variant="primary"
                                                    >
                                                        <EditIcon />
                                                        Edit
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    );
}

export default ViewUserComponent;