import React, { useCallback, useEffect, useState } from "react";
import { userService } from "../../Services/userServices";
import {
  Container,
  Col,
  Row,
  Button,
  Image,
  ButtonGroup,
} from "react-bootstrap";
import Map from "./MapView";
import {
  LocalParking,
  FreeBreakfastOutlined,
  LocalHospitalOutlined,
  StarTwoTone,
  LocalCarWashOutlined,
  ArrowBackIos,
  PrintRounded,
  Edit,
} from "@material-ui/icons";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import apiUrl from "../../constants";
import StorageService from "../../storage/StorageService";

const MapViewComponent = (props) => {
  const { location, history } = props;
  const [mapData, setMapData] = useState([]);
  const [mapMode, setMapMode] = useState(window.google.maps.MapTypeId.ROADMAP);
  const [mapModeActive, setMapModeActive] = useState("ROADMAP");
  const [latLngData, setLatLngData] = useState({});
  const [directionsService] = useState(
    new window.google.maps.DirectionsService()
  );
  const [overLay, setOverLay] = useState(false);

  const getMapDetails = useCallback(async () => {
    try {
      document.getElementById("panel").innerHTML = "";
      let data;
      if (location.state.mapId && location.state.mapId !== null) {
        data = await userService.httpApi(
          "GET",
          `admin/map/${location.state.mapId}`,
          null
        );
        setMapData(data);
      } else {
        history.goBack();
      }
      var index = data.otherLocation.findIndex(function (o) {
        return o.type === "locationTo";
      });
      if (index !== -1) {
        const latLng = {
          lat: data.otherLocation[index].locLatLng.coordinates[0],
          lng: data.otherLocation[index].locLatLng.coordinates[1],
        };
        setLatLngData(latLng);
        var map = new window.google.maps.Map(
          document.getElementById("mapViewMain"),
          {
            center: latLng,
            zoom: 15,
            mapTypeId: mapMode,
            fullscreenControl: true,
            disableDefaultUI: true,
          }
        );

        var singleMap = new window.google.maps.Map(
          document.getElementById("singleMap"),
          {
            center: latLng,
            zoom: 15,
            mapTypeId: mapMode,
            disableDefaultUI: true,
          }
        );

        singleMap.setOptions({ draggable: false });

        new window.google.maps.Marker({
          title: "Location",
          position: latLng,
          map: singleMap,
        });

        data.otherLocation.forEach((element) => {
          let icon;
          switch (element.type) {
            case "parking":
              icon = "parkinglot.png";
              document.getElementById("parking").innerHTML = element.location;
              break;
            case "trucking":
              icon = "truck.png";
              document.getElementById("location").innerHTML = element.location;
              break;
            case "locationTo":
              icon = "green-dot.png";
              break;
            case "hospital":
              icon = "hospitals.png";
              document.getElementById("hospital").innerHTML = element.location;
              break;
            case "restaurant":
              icon = "restaurant.png";
              document.getElementById("restaurant").innerHTML =
                element.location;
              break;
            case "locationFrom":
              icon = "blue-dot.png";
              break;
            default:
              break;
          }

          let latLngData = {
            lat: element.locLatLng.coordinates[0],
            lng: element.locLatLng.coordinates[1],
          };

          var mapIcon = {
            url: `${apiUrl}public/map-icons/${icon}`, // url
            scaledSize: new window.google.maps.Size(30, 30),
          };

          new window.google.maps.Marker({
            title: element.type,
            position: latLngData,
            map: map,
            icon: element.type === "locationTo" ? "" : mapIcon,
            iconType: element.type,
          });

          if (element.type === "locationFrom") {
            var start = `${latLng.lat}, ${latLng.lng}`;
            var end = `${element.locLatLng.coordinates[0]}, ${element.locLatLng.coordinates[1]}`;
            var request = {
              origin: start,
              destination: end,
              travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
            };
            directionsService.route(request, function (response, status) {
              if (status === window.google.maps.DirectionsStatus.OK) {
                new window.google.maps.DirectionsRenderer({
                  zoom: 1,
                  suppressMarkers: true,
                  map: map,
                  directions: response,
                  panel: document.getElementById("panel"),
                  polylineOptions: {
                    strokeColor: "#6991fd",
                    strokeOpacity: 1.0,
                    strokeWeight: 5,
                  },
                });
              }
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [directionsService, location.state.mapId, history, mapMode]);

  useEffect(() => {
    setOverLay(userService.overLay(true));
    getMapDetails();
    setTimeout(() => {
      setOverLay(userService.overLay(false));
    }, 1000);
  }, [getMapDetails]);

  const goBack = () => {
    history.goBack();
  };

  const addFooters = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Powered by Where's Set - http://www.wheresset.com`,
        doc.internal.pageSize.width / 2,
        295,
        {
          align: "center",
        }
      );
    }
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, { useCORS: true, scale: "1" }).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 0, position, imgWidth - 20, imgHeight);
      doc.textWithLink(
        "Go to Map Location: Click here",
        doc.internal.pageSize.getWidth() / 2,
        160,
        {
          align: "left",
          url: `https://www.google.com/maps/?q=${latLngData.lat},${latLngData.lng}`,
        }
      );
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth - 20, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      addFooters(doc);
      doc.save(`${mapData.map.mapTitle}.pdf`);
    });
  };

  const edit = () => {
    props.history.push({
      pathname: "/maps/edit",
      search: "",
      state: { mapId: location.state.mapId },
    });
  };

  const changeMapMode = (mode) => {
    setMapModeActive(mode);
    switch (mode) {
      case "ROADMAP":
        setMapMode(window.google.maps.MapTypeId.ROADMAP);
        break;
      case "SATELLITE":
        setMapMode(window.google.maps.MapTypeId.SATELLITE);
        break;
      case "HYBRID":
        setMapMode(window.google.maps.MapTypeId.HYBRID);
        break;
      case "TERRAIN":
        setMapMode(window.google.maps.MapTypeId.TERRAIN);
        break;

      default:
        break;
    }
  };

  return (
    <div className="map-view mt-2">
      {overLay}
      <Container>
        <Row>
          <Col>
            <Button className="float-right" size="sm" onClick={() => goBack()}>
              <ArrowBackIos />
              Back
            </Button>

            <Button
              size="sm"
              title="Print Map in Pdf document"
              className="btn-success"
              onClick={() => printDocument()}
            >
              PDF
              <PrintRounded className="ml-1" />
            </Button>
            {StorageService.getLogin().profile.userType !== "Crew" && (
              <Button size="sm" className="ml-1" onClick={() => edit()}>
                <Edit />
              </Button>
            )}
            <ButtonGroup className="ml-1" aria-label="Basic example">
              <Button
                size="sm"
                variant={mapModeActive === "ROADMAP" ? "secondary" : "primary"}
                onClick={() => changeMapMode("ROADMAP")}
              >
                ROADMAP
              </Button>
              <Button
                size="sm"
                variant={mapModeActive === "HYBRID" ? "secondary" : "primary"}
                onClick={() => changeMapMode("HYBRID")}
              >
                HYBRID
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>

      <Container id="divToPrint" className="pt-5">
        <Row>
          <Col sm>
            <Container className="text-center">
              <div className="row mr-0 justify-content-center">
                <Image
                  alt="Map Logo"
                  className="center-block mb-2"
                  src={apiUrl + mapData.map.mapLogo}
                  fluid
                  rounded
                  width={100}
                />

                <h2 className="text-uppercase font-italic ml-2 d-flex align-items-center justify-content-center">
                  {mapData.map.mapTitle}
                </h2>
              </div>
            </Container>
            <Container>
              <div className="first-box">
                <p className="text-center col-text-1">
                  <b>{mapData.map.location}</b>
                </p>
                <Row className="rsi-box">
                  <Col>
                    <p className="text-center col-text text-uppercase">
                      <b>Map is to scale</b>
                    </p>
                  </Col>
                  <Col>
                    <p className="text-center col-text text-uppercase">
                      <b>{"fri & mon may 21 & 24, 2021"}</b>
                    </p>
                  </Col>
                  <Col className="border-right-0">
                    <p className="text-center col-text text-uppercase">
                      <b>{"crew / bg"}</b>
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
            <Container>
              <Row className="row align-items-center mt-2">
                <Col>
                  <div
                    className="qr-code"
                    title="Aim the camera on your smart phone on the QR Code to pull up google maps link to CREW / BG PARKING"
                  >
                    <div className="qr-border text-center">
                      <h5 className="text-center">QR CODE</h5>
                      <QRCode
                        size={90}
                        value={`https://www.google.com/maps/?q=${latLngData.lat},${latLngData.lng}`}
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="loc-manager text-center">
                    <b>{mapData.map.contactInfo}</b>
                  </div>
                </Col>
              </Row>
            </Container>
            <Row>
              <div className="pl-5 pr-5 mt-4">
                <h5>
                  <ins>Notes-</ins>
                </h5>
                <div
                  dangerouslySetInnerHTML={{ __html: mapData.map.notes }}
                  className="pl-5"
                />
              </div>
            </Row>
            <Row className="d-flex p-5 justify-content-center">
              <div className="location-steps">
                <small id="panel"></small>
              </div>
            </Row>
          </Col>
          <Col className="position-relative" id="map">
            <div className="small-box position-absolute bg-white">
              <p className="text-uppercase text-center small-box-text">
                <b>look for :</b>
              </p>
              <div className="small-box-internal mt-1">
                <p className="text-uppercase text-center small-box-text">
                  <b>{mapData.map.signLabel}</b>
                </p>
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                <p className="text-uppercase mb-1 text-center small-box-text rotate">
                  <b>{mapData.map.signLabel}</b>
                </p>
              </div>
            </div>

            <div
              style={{
                height: "50vh",
                width: "100%",
                border: "2px solid #000",
                marginTop: "20px",
              }}
            >
              <Map
                height="49.5vh"
                width="100%"
                id="mapViewMain"
                options={{
                  center: {
                    lat: 34.12086,
                    lng: -118.24923,
                  },
                  zoom: 15,
                  mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                  disableDefaultUI: true,
                }}
                onMapLoad={(map) => {}}
              />
            </div>

            <Row className="mb-5 mt-3">
              <Col className="locations-box mt-2">
                <Row>
                  <Col className="locations-box-first space-125">
                    <div className="text-center">
                      <LocalParking className="icon-mui" />
                      <small className="box-heading col-text text-uppercase">
                        <u>crew / bg parking</u>
                      </small>
                      <small
                        className="box-heading-internal"
                        id="parking"
                      ></small>
                    </div>
                  </Col>
                  <Col className="locations-box-second space-125">
                    <div className="text-center">
                      <StarTwoTone className="icon-mui" />
                      <LocalCarWashOutlined className="icon-mui" />
                      <small className="box-heading col-text text-uppercase">
                        <u>location-basecamp-trucks</u>
                      </small>
                      <small
                        className="box-heading-internal"
                        id="location"
                      ></small>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="locations-box-third space-125">
                    <div className="text-center">
                      <LocalHospitalOutlined className="icon-mui" />
                      <small className="box-heading col-text text-uppercase">
                        <u>nearest hospital</u>
                      </small>
                      <small
                        className="box-heading-internal"
                        id="hospital"
                      ></small>
                    </div>
                  </Col>
                  <Col className="space-125">
                    <div className="text-center">
                      <FreeBreakfastOutlined className="icon-mui" />
                      <small className="box-heading col-text text-uppercase">
                        <u>catering</u>
                      </small>
                      <small
                        className="box-heading-internal"
                        id="restaurant"
                      ></small>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="locations-box p-0 mt-2">
                <Map
                  height="250px"
                  id="singleMap"
                  options={{
                    center: {
                      lat: 34.12086,
                      lng: -118.24923,
                    },
                    zoom: 7,
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    disableDefaultUI: true,
                  }}
                  onMapLoad={(map) => {}}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MapViewComponent;
