import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  ListGroup,
  Card,
} from "react-bootstrap";
import styles from "./UsersComponent.module.css";
import { userService } from "../../Services/userServices";
import { DeleteForever, Edit, RemoveRedEye, Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import MyPagination from "../../Services/pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import StorageService from "../../storage/StorageService";
import { Tables } from "../Helpers/Tables";

const UsersComponent = (props) => {
  const { userType } = props;
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [key, setKey] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [overLay, setOverLay] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [teamsLength, setTeamsLength] = useState("");
  const [table, setTable] = useState("");
  const [tableHead] = useState([
    "#",
    "First Name",
    "Last Name",
    "Email",
    "User Type",
    "Status",
    "Actions",
  ]);
  const [icons] = useState({
    Edit: <Edit />,
    View: <RemoveRedEye />,
    Delete: <DeleteForever />,
  });
  const [buttonColor] = useState({
    Edit: "primary",
    View: "success",
    Delete: "danger",
  });

  const editTeam = useCallback(
    (_id) => {
      props.history.push({
        pathname: "/teams/edit",
        search: "",
        state: { id: _id },
      });
    },
    [props.history]
  );

  const checkUserExistInTeam = useCallback(
    async (_id, userType) => {
      try {
        const res = await userService.httpApi(
          "GET",
          `admin/team-user/check/${_id}`,
          null
        );
        if (res.length > 0 && res !== null) {
          if (userType === "Manager") {
            const modalDataV = res.slice(0, 5).map((x, i) => {
              return (
                <ListGroup.Item key={i}>
                  {x.teamId.teamName}
                  <Button
                    className="float-right"
                    onClick={() => editTeam(x.teamId._id)}
                    color="primary"
                  >
                    Change
                  </Button>
                </ListGroup.Item>
              );
            });
            setTeamsLength(
              `This user exists in ${res.length} ${
                res.length > 1 ? "Teams" : "Team"
              } as a ${userType}`
            );
            setModalShow(true);
            setModalData(modalDataV);
          } else {
            Swal.fire({
              title: "Are you sure?",
              html: `<div class="card"><div class="card-body"><h5 class="card-title">User Exists in ${
                res.length
              } ${
                res.length > 1 ? "Teams" : "Team"
              }</h5><h6 class="card-subtitle mb-2 text-muted">Teams List</h6><ul class="list-group">${res
                .slice(0, 5)
                .map(
                  (x, i) =>
                    `<li class="list-group-item" key=${i}>${x.teamId.teamName}</li>`
                )
                .join("")}</ul></div></div>`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No, keep it",
            }).then(async (result) => {
              if (result.value) {
                await userService.httpApi(
                  "DELETE",
                  `admin/team-user/check/${_id}`,
                  null
                );
                setKey(Math.random());
                Swal.fire("Deleted!", "User has been deleted.", "success");
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "user is safe &#128526;", "error");
              }
            });
          }
        } else {
          handleDelete(_id);
        }
      } catch (err) {
        toast.warn(err.message);
      }
    },
    [editTeam]
  );

  const handleView = useCallback(
    (_id) => {
      setOverLay(userService.overLay(true));
      userService
        .httpApi("GET", `admin/users/${_id}`, null)
        .then((res) => {
          props.history.push({
            pathname: "/users/view",
            search: "",
            state: { details: res },
          });
        })
        .catch((err) => console.log(err));
    },
    [props.history]
  );

  const handleEdit = useCallback(
    (_id) => {
      setOverLay(userService.overLay(true));
      userService
        .httpApi("GET", `admin/users/${_id}`, null)
        .then((res) => {
          props.history.push({
            pathname: "/users/edit",
            search: "",
            state: { details: res },
          });
        })
        .catch((err) => console.log(err));
    },
    [props.history]
  );

  const drawTable = useCallback(
    (user, i) => {
      return {
        sno: (currPage - 1) * perPage + i + 1,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userType: user.userType,
        status: user.status,
        actions: ["Edit", "View", "Delete"].map((res, i) => {
          return Tables.tableButton(
            {
              name: res,
              color: buttonColor[res],
              size: "sm",
              index: i,
              icon: icons[res],
            },
            function () {
              switch (res) {
                case "Edit":
                  return handleEdit(user._id);

                case "View":
                  return handleView(user._id);

                case "Delete":
                  return checkUserExistInTeam(user._id, user.userType);

                default:
                  break;
              }
            }
          );
        }),
      };
    },
    [
      buttonColor,
      checkUserExistInTeam,
      currPage,
      handleEdit,
      handleView,
      icons,
      perPage,
    ]
  );

  const getUsersList = useCallback(
    async (page) => {
      setCurrPage(page);
      if (userType === "Admin") {
        await userService
          .httpApi("GET", `admin/users/${page}/${perPage}`, null)
          .then((res) => {
            const data = res.users.map((resp, i) => drawTable(resp, i));
            setTable(Tables.drawTable(tableHead, data));
            setTotalPage(Math.ceil(res.totalCount / perPage));
          })
          .catch((error) => {
            if (error === "Token Expired") {
              toast.warn(error);
              StorageService.clearLogin();
            } else if (error === "Login Session Expired") {
              toast.warn(error);
              StorageService.clearLogin();
              props.history.push(`/login`);
            } else {
              toast.warn(error);
            }
          });
      } else {
        await userService
          .httpApi("GET", `manager/users/${page}/${perPage}`, null)
          .then((res) => {
            const data = res.users.map((resp, i) => drawTable(resp, i));
            setTable(Tables.drawTable(tableHead, data));
            setTotalPage(Math.ceil(res.totalCount / perPage));
          })
          .catch((error) => {
            if (error === "Token Expired") {
              toast.warn(error);
              StorageService.clearLogin();
            } else if (error === "Login Session Expired") {
              toast.warn(error);
              StorageService.clearLogin();
              props.history.push(`/login`);
            } else {
              toast.warn(error);
            }
          });
      }
      setOverLay(userService.overLay(false));
      setSpinner(false);
    },
    [userType, perPage, tableHead, drawTable, props.history]
  );

  useEffect(() => {
    document.title = "Map App - Users";
    setOverLay(userService.overLay(true));
    setSpinner(true);
    getUsersList(currPage);
  }, [perPage, key, getUsersList, currPage]);

  const perPageCount = (e) => {
    setCurrPage(1);
    setPerPage(e.target.value);
  };

  const handleDelete = (_id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        html: "You will not be able to recover this user",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then(async (result) => {
        if (result.value) {
          await userService.httpApi("DELETE", `admin/users/${_id}`, null);
          setKey(Math.random());
          Swal.fire("Deleted!", "User has been deleted.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "user is safe &#128526;", "error");
        }
      });
    } catch (error) {
      toast.warn(error.message);
    }
  };

  return (
    <div className={styles.UsersComponent}>
      {overLay}
      <Container>
        <Row>
          <Col className="p-3 mb-5 mt-5 form-outer">
            <Row className="border-bottom">
              <Col>
                <div className="from-heading">
                  <h2>Users</h2>
                </div>
              </Col>
              <Col>
                <Button
                  className="float-right"
                  as={Link}
                  to="/users/add"
                  variant="primary"
                >
                  <Add />
                  Add User
                </Button>
              </Col>
            </Row>
            {spinner ? "Loading..." : table}
            <Row>
              <Col>
                <Form.Control
                  className="w-auto"
                  name="userType"
                  as="select"
                  value={perPage || ""}
                  onChange={perPageCount}
                  style={{ marginTop: "15px" }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Form.Control>
              </Col>
              <Col>
                <MyPagination
                  totPages={totalPage}
                  currentPage={currPage}
                  pageClicked={(e) => getUsersList(e)}
                ></MyPagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h3>{teamsLength}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">
                {
                  "Please change the manager from the team first after that you can delete this user."
                }
              </Card.Subtitle>
              <ListGroup>{modalData}</ListGroup>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userType: state.userType,
  };
};

export default connect(mapStateToProps)(UsersComponent);
