import React from "react";

function OverLay(props) {
  const { overLay } = props;
  return (
    <>
      {overLay && (
        <div
          id="myNav"
          className="overlay1 d-flex align-items-center justify-content-center"
        >
          <div className="overlay-content1">
            <div
              className="spinner-border text-dark"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only"></span>
            </div>
            <span className="d-block text-dark">
              <b>Loading content...</b>
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default OverLay;
