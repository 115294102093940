import { connect } from "react-redux";
import Header from "../components/Layout/Header";
import { logout } from "../Services/Actions/actions";

const mapStateToProps = state => ({
    isLogged: state.isLogged,
    userDetails: state.login,
    userType: state.userType
})
const mapDispatchToProps = dispatch => ({
    logout: data => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);