import React, { useCallback, useEffect, useState } from 'react';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import styles from './TeamsComponent.module.css';
import { userService } from '../../Services/userServices';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import StorageService from '../../storage/StorageService';
import Multiselect from 'multiselect-react-dropdown';

const EditTeamsComponent = (props) => {
    const _id = props.location.state.id
    const initialValues = {                   // type all the fields you need
        teamName: '',
        project: '',
        manager: ''
    };

    const [fielsData, setValues] = useState(initialValues);
    const [errors, setErrorValues] = useState(initialValues);
    const [formIsValid, setFormIsValid] = useState(false);
    const [overLay, setOverLay] = useState(false);
    const [optionsCrews, setOptionsCrews] = useState([])
    const [selectedValues, setSelectedvalues] = useState([])
    const [selectedCrews, setSelectedCrews] = useState([])
    const [optionsManager, setOptionsManager] = useState([])
    const [optionsProject, setOptionsProject] = useState([])
    const [optionsMap, setOptionsMap] = useState([])
    const [selectedMaps, setSelectedMaps] = useState([])
    const [selectedMapValues, setSelectedMapValues] = useState([])


    const getTeams = useCallback(async () => {
        try {
            let selectedCrewsArr = [];
            let selectedProjectsArr = [];
            let selectedManagersArr = [];
            let selectedCrews = [];
            let selectedProjects = [];
            let selectedManagers = [];
            let crewsData = [];
            let selectedMapsArr = [];
            let selectedMaps = [];
            let mapsData = [];
            const data = await userService.httpApi('GET', `admin/teams/${_id}`, null);

            data.teamCrews.map(x => (
                x.crews.map(y => selectedCrewsArr.push({
                    id: y._id,
                    name: y.firstName + ' ' + y.lastName + ' (' + y.email + ')',
                })
                )));
            data.teamCrews.map(x => (
                x.crews.map(y => selectedCrews.push(y._id)
                )));

            data.teamMaps.map(x => (
                x.maps.map(y => selectedMapsArr.push({
                    id: y._id,
                    name: y.mapTitle,
                })
                )));
            data.teamMaps.map(x => (
                x.maps.map(y => selectedMaps.push(y._id)
                )));

            data.teamProjects.map(x => (
                x.projects.map(y => selectedProjectsArr.push({
                    id: y._id,
                    name: y.projectName,
                })
                )));
            data.teamProjects.map(x => (
                x.projects.map(y => selectedProjects.push(y._id)
                )));
            data.teamManagers.map(x => (
                x.managers.map(y => selectedManagersArr.push({
                    id: y._id,
                    name: y.firstName + ' ' + y.lastName + ' (' + y.email + ')',
                })
                )));
            data.teamManagers.map(x => (
                x.managers.map(y => selectedManagers.push(y._id)
                )));

            setValues({
                teamName: data.team.teamName,
                project: selectedProjects[0],
                manager: selectedManagers[0]
            })

            setSelectedvalues(selectedCrewsArr)
            selectedCrewsArr.map(x => crewsData.push(x.id))
            setSelectedCrews(crewsData)

            setSelectedMapValues(selectedMapsArr)
            selectedMapsArr.map(x => mapsData.push(x.id))
            setSelectedMaps(mapsData)

        } catch (error) {
            toast.warn(error.message);
        }
    }, [_id])

    const getManager = useCallback(async () => {
        let arr = [];
        if (StorageService.getLogin().profile.userType === 'Manager') {
            arr.push({
                id: StorageService.getLogin().profile.userId,
                name: StorageService.getLogin().profile.firstName + ' ' + StorageService.getLogin().profile.lastName + ' (' + StorageService.getLogin().profile.email + ')',
            })
            setValues({ manager: StorageService.getLogin().profile.userId })
            setOptionsManager(arr);
        } else {
            try {
                const data = await userService.httpApi('GET', `manager`, null);
                data.map((x) =>
                    arr.push({
                        id: x._id,
                        name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                    })
                )
                setOptionsManager(arr);
            } catch (error) {
                if (error === 'Token Expired') {
                    toast.warn(error);
                    StorageService.clearLogin();
                } else if (error === 'Login Session Expired') {
                    toast.warn(error);
                    props.history.push(`/login`);
                    StorageService.clearLogin();
                } else {
                    alert('ok')
                    toast.warn(error);
                }
            }
        }
    }, [props.history])

    useEffect(() => {
        getTeams()
        getManager()
        getCrews()
        getMaps()
        getProjects()
    }, [getTeams, getManager])

    const getCrews = async () => {
        try {
            let arr = [];
            const data = await userService.httpApi('GET', `crew`, null);
            data.map((x) =>
                arr.push({
                    id: x._id,
                    name: x.firstName + ' ' + x.lastName + ' (' + x.email + ')',
                })
            )
            setOptionsCrews(arr);
        } catch (error) {
            toast.warn(error);
        }
    }

    const getMaps = async () => {
        try {
            let arr = [];
            const data = await userService.httpApi('GET', `admin/maps`, null);
            data.map((x) =>
                arr.push({
                    id: x._id,
                    name: x.mapTitle,
                })
            )
            setOptionsMap(arr);
        } catch (error) {
            toast.warn(error);
        }
    }

    const getProjects = async () => {
        try {
            let arr = [];
            const data = await userService.httpApi('GET', `projects`, null);
            data.map((x) =>
                arr.push({
                    id: x._id,
                    name: x.projectName,
                })
            )
            setOptionsProject(arr);
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    const handleValidation = () => {
        let fields = fielsData;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["teamName"]) {
            formIsValid = false;
            errors["teamName"] = "Team name cannot be empty";
        }

        if (!fields["project"]) {
            formIsValid = false;
            errors["project"] = "Project cannot be empty";
        }

        if (!fields["manager"]) {
            formIsValid = false;
            errors["manager"] = "Manager cannot be empty";
        }

        setFormIsValid(formIsValid)
        setErrorValues(errors)
        return formIsValid;
    }

    const handleChange = (e) => {
        setValues({ ...fielsData, [e.target.name]: e.target.value });
    };

    const handleUserInput = async (event) => {
        setOverLay(userService.overLay(true))
        event.preventDefault();
        try {
            if (handleValidation()) {
                const data = {
                    teamName: fielsData.teamName,
                    project: fielsData.project,
                    manager: fielsData.manager,
                    createdBy: StorageService.getLogin().profile.userId,
                    crew: JSON.stringify(selectedCrews),
                    map: JSON.stringify(selectedMaps)
                };
                await userService.httpApi('PATCH', `admin/teams/${_id}`, JSON.stringify(data));
                toast.success('Team updated successfully');
                setTimeout(() => {
                    props.history.push('/teams')
                }, 800)
            } else {
                setOverLay(userService.overLay(false))
            }
        } catch (error) {
            toast.warn(error);
            setOverLay(userService.overLay(false))
        }
    }

    const onSelectCrew = (selectedList, selectedItem) => {
        const list = selectedList.map(x => x.id);
        setSelectedCrews(list)
    }

    const onRemoveCrew = (selectedList, removedItem) => {
        const list = selectedList.map(x => x.id);
        setSelectedCrews(list)
    }

    const onSelectMaps = (selectedList, selectedItem) => {
        const list = selectedList.map(x => x.id);
        setSelectedMaps(list)
    }

    const onRemoveMaps = (selectedList, removedItem) => {
        const list = selectedList.map(x => x.id);
        setSelectedMaps(list)
    }

    return (
        <div className={styles.TeamsComponent} >
            {overLay}
            <div className={styles.LoginComponent}>
                <Container>
                    <Row>
                        <Col className="p-3 mt-5 form-outer">
                            <Row className="border-bottom">
                                <Col>
                                    <div className="from-heading">
                                        <h2>Edit Team</h2>
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        className="float-right"
                                        as={Link}
                                        to="/teams"
                                        variant="primary"
                                    >
                                        <ArrowBackIosIcon />
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                            <Form className="mt-3" noValidate validated={formIsValid} onSubmit={handleUserInput}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicFName">
                                            <Form.Label>Team Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Team Name"
                                                name="teamName"
                                                value={fielsData.teamName || ''}
                                                isInvalid={!!errors.teamName}
                                                onChange={handleChange}
                                            />
                                            {errors.teamName && <Form.Text className="text-danger">
                                                {errors.teamName}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formGridStatus">
                                            <Form.Label>Project</Form.Label>
                                            <Form.Control
                                                name="project"
                                                as="select"
                                                value={fielsData.project || ''}
                                                onChange={handleChange}
                                            >
                                                <option value="" >Select Project..</option>
                                                {optionsProject.map((project, index) => <option key={index} value={project.id}>{project.name}</option>)}
                                            </Form.Control>
                                            {errors.project && <Form.Text className="text-danger">
                                                {errors.project}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formGridStatus">
                                            <Form.Label>Manager</Form.Label>
                                            <Form.Control
                                                name="manager"
                                                as="select"
                                                value={fielsData.manager || ''}
                                                onChange={handleChange}
                                            >
                                                <option value="" >Select Manager..</option>
                                                {optionsManager.map((manager, index) => <option key={index} value={manager.id}>{manager.name}</option>)}
                                            </Form.Control>
                                            {errors.manager && <Form.Text className="text-danger">
                                                {errors.manager}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formGridStatus">
                                            <Form.Label>Crew</Form.Label>
                                            <Multiselect
                                                showCheckbox={true}
                                                avoidHighlightFirstOption={true}
                                                options={optionsCrews} // Options to display in the dropdown
                                                selectedValues={selectedValues} // Preselected value to persist in dropdown
                                                onSelect={onSelectCrew} // Function will trigger on select event
                                                onRemove={onRemoveCrew} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formGridStatus">
                                            <Form.Label>Maps</Form.Label>
                                            <Multiselect
                                                showCheckbox={true}
                                                avoidHighlightFirstOption={true}
                                                options={optionsMap} // Options to display in the dropdown
                                                selectedValues={selectedMapValues} // Preselected value to persist in dropdown
                                                onSelect={onSelectMaps} // Function will trigger on select event
                                                onRemove={onRemoveMaps} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col></Col>
                                </Row>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    );
}

export default EditTeamsComponent;