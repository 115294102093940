import React, { useEffect, useState } from "react";
import styles from "./LoginComponent.module.css";
import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { userService } from "./../../Services/userServices";
import StorageService from "../../storage/StorageService";
import { toast } from "react-toastify";

const LoginComponent = (props) => {
  const { userType } = props;
  const initialValues = {
    email: "",
    password: "",
  };
  const [fielsData, setValues] = useState(initialValues);
  const [errors, setErrorValues] = useState(initialValues);
  const [formIsValid, setFormIsValid] = useState(false);
  const [overLay, setOverLay] = useState(false);

  useEffect(() => {
    document.title = "Map App - Login";
    if (StorageService.getLogin() && StorageService.getLogin() !== null) {
      if (userType !== "Crew") {
        props.history.push("/teams");
      } else {
        props.history.push("/projects");
      }
    }
  }, [props, userType]);

  const handleValidation = () => {
    let fields = fielsData;
    let errors = {};
    let formIsValid = true;

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }

    if (
      typeof fields["email"] !== "undefined" &&
      fields["email"].length !== 0
    ) {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    }

    setFormIsValid(formIsValid);
    setErrorValues(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    setValues({ ...fielsData, [e.target.name]: e.target.value });
  };

  const handleUserInput = (event) => {
    setOverLay(userService.overLay(true));
    event.preventDefault();
    if (handleValidation()) {
      userService
        .login(fielsData)
        .then((res) => {
          if (res.flag === "success") {
            setOverLay(userService.overLay(false));
            toast.success("Logged In Successfully");
            props.userLogin(res);
            if (userType !== "Crew") {
              props.history.push("/teams");
            } else {
              props.history.push("/projects");
            }
          }
        })
        .catch((err) => {
          setOverLay(userService.overLay(false));
          toast.error(err);
        });
    }
    //setOverLay(userService.overLay(false))
  };
  return (
    <div className={styles.LoginComponent}>
      {overLay}
      <Container>
        <Row>
          <Col className="p-3 mt-5 form-outer" md={{ span: 4, offset: 4 }}>
            <div className="form-heading border-bottom">
              <h2>Login</h2>
            </div>
            <Form
              className="pt-2"
              noValidate
              validated={formIsValid}
              onSubmit={handleUserInput}
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={fielsData.email || ""}
                  isInvalid={!!errors.email}
                  onChange={handleChange}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
                {errors.email && (
                  <Form.Text className="text-danger">{errors.email}</Form.Text>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  name="password"
                  value={fielsData.password || ""}
                  isInvalid={!!errors.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <Form.Text className="text-danger">
                    {errors.password}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Check me out" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default LoginComponent;
