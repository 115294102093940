import React, { useCallback, useEffect, useState } from 'react';
import { Container, Button, Col, Row } from 'react-bootstrap';
import styles from './TeamsComponent.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import { Tables } from '../Helpers/Tables';

const TeamCrewsComponent = (props) => {
    const { location } = props;
    const [table, setTable] = useState('');
    const [tableHead] = useState(['#', 'Map Title', 'Location', 'Contact', 'Actions']);

    const clickHandler = useCallback((_id) => {
        props.history.push({
            pathname: '/maps/view',
            search: '',
            state: { mapId: _id }
        });
    }, [props.history])

    const drawTable = useCallback((resp, i) => {
        return {
            sno: i + 1,
            name: resp.mapTitle,
            location: resp.location,
            contactInfo: resp.contactInfo,
            actions: Tables.tableButton({ name: 'View', color: 'primary', size: 'sm' }, function () {
                return clickHandler(resp._id)
            })
        }
    }, [clickHandler])

    const setMapData = useCallback(() => {
        const mapData = location.state.details.map((x, i) => x.maps[0])
        const data = mapData.map((resp, i) => drawTable(resp, i))
        setTable(Tables.drawTable(tableHead, data))
    }, [drawTable, location.state.details, tableHead])


    useEffect(() => {
        setMapData()
    }, [setMapData])

    return (
        <div className={styles.TeamsComponent} >
            <Container>
                <Row>
                    <Col className="p-3 mt-5 form-outer">
                        <Row className="border-bottom">
                            <Col>
                                <div className="from-heading">
                                    <h2>All Team's Maps</h2>
                                </div>
                            </Col>
                            <Col>
                                <Button
                                    className="float-right"
                                    as={Link}
                                    to="/teams"
                                    variant="primary"
                                >
                                    <ArrowBackIosIcon />
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        {table}
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

export default TeamCrewsComponent;