import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './DashboardComponent.module.css';

const DashboardComponent = () => {
  return (
    <div className={styles.DashboardComponent}>
      <Container>
        <Row>
          <Col className="p-3 mt-5 form-outer" >
            <div className="form-heading">
              <h2>Dashboard</h2>
            </div>
            
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default DashboardComponent;
