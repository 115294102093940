import React, { useCallback, useEffect, useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import styles from "./MapsComponent.module.css";
import StorageService from "../../storage/StorageService";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import apiUrl from "../../constants";
import Image from "react-bootstrap/Image";
import { userService } from "../../Services/userServices";
import MapLoad from "./MapLoad";
import { Modal, ListGroup } from "react-bootstrap";
import {
  LocalParking,
  FreeBreakfastOutlined,
  LocalHospitalOutlined,
  StarTwoTone,
  LocalCarWashOutlined,
} from "@material-ui/icons";

const MapEditComponent = (props) => {
  const { location } = props;
  const initialValues = {
    // type all the fields you need
    mapTitle: "",
    location: "",
    signLabel: "",
    contactInfo: "",
    notes: "",
    status: false,
  };

  const options = {
    center: {
      lat: 34.12086,
      lng: -118.24923,
    },
    zoom: 15,
    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
  };

  const [fieldsData, setValues] = useState(initialValues);
  const [errors, setErrorValues] = useState(initialValues);
  const [notes, setNotes] = useState("");
  const [locationM, setLocationM] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [mapLogo, setMapLogo] = useState(null);
  const [preview, setPreview] = useState({});
  const [allowedExtn] = useState(["png", "jpg", "jpeg", "gif"]);
  const [otherLocation, setOtherLocation] = useState([]);
  const [mapOption] = useState(options);
  const [mapLatLng, setMapLatLng] = useState({});
  const [mapMarkers, setMapMarkers] = useState([]);
  const [directionsService] = useState(
    new window.google.maps.DirectionsService()
  );
  const [modal, setModal] = useState(false);
  const [iconLocation, setIconLocation] = useState({});
  const [mapE, setMapE] = useState({});
  const [geoAddress, setGeoAddress] = useState("");
  const [locationTo, setLocationTo] = useState(false);
  const [key, setKey] = useState("");

  const getMapDetails = useCallback(async () => {
    try {
      const data = await userService.httpApi(
        "GET",
        `admin/map/${location.state.mapId}`,
        null
      );

      var index = data.otherLocation.findIndex(function (o) {
        return o.type === "locationTo";
      });
      if (index !== -1) {
        setLocationTo(true);
        const latLng = {
          lat: data.otherLocation[index].locLatLng.coordinates[0],
          lng: data.otherLocation[index].locLatLng.coordinates[1],
        };

        setMapLatLng(latLng);
      }

      if (data.map.mapLogo !== null && data.map.mapLogo) {
        setMapLogo(true);
        setPreview(apiUrl + data.map.mapLogo);
      }
      setOtherLocation(data.otherLocation);
      setNotes(data.map.notes);
      setLocationM(data.map.location);
      setValues(data.map);
    } catch (error) {
      console.log(error);
    }
  }, [location.state.mapId]);

  useEffect(() => {
    getMapDetails();
    setSpinner(true);
    setTimeout(() => {
      setSpinner(false);
    }, 3000);
  }, [getMapDetails, key]);

  const mapInit = (map) => {
    otherLocation.forEach((element, i) => {
      let icon;
      switch (element.type) {
        case "parking":
          icon = "parkinglot.png";

          break;
        case "trucking":
          icon = "truck.png";

          break;
        case "locationTo":
          icon = "green-dot.png";

          break;
        case "hospital":
          icon = "hospitals.png";

          break;
        case "restaurant":
          icon = "restaurant.png";

          break;
        case "locationFrom":
          icon = "blue-dot.png";

          break;
        default:
          break;
      }
      const latLng = {
        lat: element.locLatLng.coordinates[0],
        lng: element.locLatLng.coordinates[1],
      };
      if (locationTo) {
        placeMarker(map, icon, element.type, latLng, element._id);
      }
    });
  };

  const placeMarker = (map, icon, iconType, position, locationId) => {
    var mapIcon = {
      url: `${apiUrl}public/map-icons/${icon}`, // url
      scaledSize: new window.google.maps.Size(30, 30),
    };

    var marker = new window.google.maps.Marker({
      title: "Right Click for delete icon",
      position: position,
      icon: iconType === "locationTo" ? "" : mapIcon,
      iconType: iconType,
      locationId: locationId,
      map: map,
      draggable: true,
    });

    marker.addListener("rightclick", (e) => {
      if (marker.iconType === "locationTo") {
        setLocationTo(false);
      }
      if (
        marker.iconType === "locationTo" ||
        marker.iconType === "locationFrom"
      ) {
        setKey(Math.random());
      }
      deleteOtherLocations(marker.locationId);
      marker.setMap(null);
    });

    marker.addListener("dragend", (e) => {
      var index = mapMarkers.findIndex(function (o) {
        return o.type === iconType;
      });

      if (index !== -1) mapMarkers.splice(index, 1);
      if (iconType === "locationTo") {
        var origin = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        };
        setMapLatLng(origin);
      }
      setKey(Math.random());
      updateOtherLocations(marker.locationId, e.latLng);
    });

    mapMarkers.push(marker);
    setMapMarkers(mapMarkers);
    if (iconType === "locationFrom") {
      directionService(map, mapLatLng, position);
    }
  };

  const addOtherLocation = async (map, latLng, location) => {
    setMapE(map);
    setGeoAddress(location);
    setIconLocation(latLng);
    setModal(true);
  };

  const updateOtherLocations = async (_id, latLng) => {
    try {
      const data = {
        locLatLng: {
          type: "Point",
          coordinates: [latLng.lat(), latLng.lng()],
        },
      };
      await userService.httpApi(
        "PATCH",
        `admin/map/other-location/${_id}`,
        JSON.stringify(data)
      );
    } catch (error) {
      toast.warn(error);
    }
  };

  const deleteOtherLocations = async (_id) => {
    try {
      await userService.httpApi(
        "DELETE",
        `admin/map/other-location/${_id}`,
        null
      );
    } catch (error) {
      toast.warn(error);
    }
  };

  const getIconType = async (e) => {
    setModal(false);
    let icon, iconType;
    switch (e.target.id) {
      case "1":
        icon = "green-dot.png";
        iconType = "locationTo";
        setLocationTo(true);
        setKey(Math.random());
        break;
      case "2":
        icon = "parkinglot.png";
        iconType = "parking";
        setKey(Math.random());
        break;
      case "4":
        icon = "truck.png";
        iconType = "trucking";
        setKey(Math.random());
        break;
      case "3":
        icon = "hospitals.png";
        iconType = "hospital";
        setKey(Math.random());
        break;
      case "5":
        icon = "restaurant.png";
        iconType = "restaurant";
        setKey(Math.random());
        break;
      case "6":
        icon = "blue-dot.png";
        iconType = "locationFrom";
        setKey(Math.random());
        break;
      default:
        break;
    }
    if (mapMarkers.length !== 0) {
      mapMarkers.forEach((element) => {
        var marker =
          element.iconType === iconType && element.iconType !== "locationFrom"
            ? element
            : null;
        if (marker) {
          deleteOtherLocations(marker.locationId);
          marker.setMap(null);
        }
      });
    }

    const data = {
      mapId: location.state.mapId,
      type: iconType,
      location: geoAddress,
      locLatLng: {
        type: "Point",
        coordinates: [iconLocation.lat(), iconLocation.lng()],
      },
    };

    const latLng = {
      lat: iconLocation.lat(),
      lng: iconLocation.lng(),
    };

    await userService
      .httpApi("POST", `map-other-location`, JSON.stringify(data))
      .then((res) => {
        placeMarker(mapE, icon, iconType, latLng, res._id);
      });
  };

  const directionService = (map, origin, destination) => {
    var start = `${origin.lat}, ${origin.lng}`;
    var end = `${destination.lat}, ${destination.lng}`;
    var request = {
      origin: start,
      destination: end,
      travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
    };
    directionsService.route(request, function (response, status) {
      if (status === window.google.maps.DirectionsStatus.OK) {
        new window.google.maps.DirectionsRenderer({
          zoom: 1,
          suppressMarkers: true,
          draggable: false,
          map: map,
          directions: response,
          polylineOptions: {
            strokeColor: "#6991fd",
            strokeOpacity: 1.0,
            strokeWeight: 5,
          },
        });
      }
    });
  };
  const handleValidation = () => {
    let fields = fieldsData;
    let errors = {};
    let formIsValid = true;

    if (!fields["mapTitle"]) {
      formIsValid = false;
      errors["mapTitle"] = "Map Title cannot be empty";
    }

    if (!fields["contactInfo"]) {
      formIsValid = false;
      errors["contactInfo"] = "Contact cannot be empty";
    }

    if (!fields["signLabel"]) {
      formIsValid = false;
      errors["signLabel"] = "Sign label cannot be empty";
    }

    if (!notes) {
      formIsValid = false;
      errors["notes"] = "Notes cannot be empty";
    }

    if (!location) {
      formIsValid = false;
      errors["location"] = "Location cannot be empty";
    }

    setFormIsValid(formIsValid);
    setErrorValues(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    setValues({ ...fieldsData, [e.target.name]: e.target.value });
  };

  const onChangeFileHandler = (event) => {
    const extn = event.target.files[0].name.split(".").pop().toLowerCase();
    if (~allowedExtn.indexOf(extn)) {
      setMapLogo(event.target.files[0]);
      const objectUrl = URL.createObjectURL(event.target.files[0]);
      setPreview(objectUrl);
    } else {
      toast.warn("Invalid File Format.");
    }
  };

  const handleMapInput = async (event) => {
    setSpinner(true);
    event.preventDefault();
    try {
      if (handleValidation()) {
        const data = new FormData();
        data.append("mapTitle", fieldsData.mapTitle);
        data.append("mapLogo", mapLogo);
        data.append("location", locationM);
        data.append("contactInfo", fieldsData.contactInfo);
        data.append("signLabel", fieldsData.signLabel);
        data.append("status", fieldsData.status);
        data.append("notes", notes);
        data.append("createdBy", StorageService.getLogin().profile.userId);
        data.append("otherLocation", JSON.stringify(otherLocation));
        userService
          .httpApi("PATCH", `admin/map/${location.state.mapId}`, data, "Accept")
          .then((res) => {
            toast.success(res.message);
            setTimeout(() => {
              props.history.push({
                pathname: "/maps/view",
                search: "",
                state: { mapId: location.state.mapId },
              });
            }, 800);
          })
          .catch(function (error) {
            console.log(error);
            toast.warn(error);
          });
      } else {
        setSpinner(false);
      }
    } catch (error) {
      toast.warn(error);
      setSpinner(false);
    }
  };

  return (
    <div className={styles.MapsComponent}>
      <Modal
        size="sm"
        show={modal}
        onHide={(e) => setModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose icon for this location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {locationTo ? (
              <ListGroup.Item
                action
                variant="secondary"
                id="6"
                onClick={(e) => getIconType(e)}
              >
                {" "}
                Location From
                <i className="location-icon">
                  <StarTwoTone />
                </i>
              </ListGroup.Item>
            ) : (
              <ListGroup.Item
                action
                variant="secondary"
                id="1"
                onClick={(e) => getIconType(e)}
              >
                {" "}
                Location To
                <i className="location-icon">
                  <StarTwoTone />
                </i>
              </ListGroup.Item>
            )}
            <ListGroup.Item
              action
              variant="dark"
              id="2"
              onClick={(e) => getIconType(e)}
            >
              {" "}
              Parking{" "}
              <i className="location-icon">
                <LocalParking />
              </i>
            </ListGroup.Item>
            <ListGroup.Item
              action
              variant="secondary"
              id="3"
              onClick={(e) => getIconType(e)}
            >
              {" "}
              Hospital{" "}
              <i className="location-icon">
                <LocalHospitalOutlined />
              </i>
            </ListGroup.Item>
            <ListGroup.Item
              action
              variant="dark"
              id="4"
              onClick={(e) => getIconType(e)}
            >
              {" "}
              Trucks{" "}
              <i className="location-icon">
                <LocalCarWashOutlined />
              </i>
            </ListGroup.Item>
            <ListGroup.Item
              action
              variant="secondary"
              id="5"
              onClick={(e) => getIconType(e)}
            >
              {" "}
              Catering{" "}
              <i className="location-icon">
                <FreeBreakfastOutlined />
              </i>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>
      <Container>
        <Row>
          <Col className="p-3 mt-5 form-outer">
            <Row className="border-bottom">
              <Col>
                <div className="from-heading">
                  <h2>Edit Map Details</h2>
                </div>
              </Col>
              <Col>
                <Button
                  className="float-right"
                  as={Link}
                  to="/maps"
                  variant="primary"
                >
                  <ArrowBackIos />
                  Back
                </Button>
              </Col>
            </Row>
            <Form
              className="mt-3"
              noValidate
              validated={formIsValid}
              onSubmit={handleMapInput}
            >
              {/* <Row className='border border-dark'>
                                <Col className={styles.setMapBox}>

                                </Col>
                            </Row> */}
              <Row className={styles.mainMapBox}>
                <Col lg={4}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicFName">
                      <Form.Label>Map Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Map Title"
                        name="mapTitle"
                        value={fieldsData.mapTitle || ""}
                        isInvalid={!!errors.mapTitle}
                        onChange={handleChange}
                      />
                      {errors.mapTitle && (
                        <Form.Text className="text-danger">
                          {errors.mapTitle}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="file">
                      <Form.Label>Map Logo</Form.Label>
                      <Form.Control
                        type="file"
                        name="file"
                        onChange={onChangeFileHandler}
                      />
                      {mapLogo && (
                        <Image src={preview} fluid thumbnail width="80" />
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        type="text"
                        id="auto-place-edit"
                        placeholder="Location"
                        name="location"
                        value={locationM || ""}
                        isInvalid={!!errors.location}
                        onChange={(e) => setLocationM(e.target.value)}
                      />
                      {errors.location && (
                        <Form.Text className="text-danger">
                          {errors.location}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicFName">
                      <Form.Label>Sign Label</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sign Label"
                        name="signLabel"
                        value={fieldsData.signLabel || ""}
                        isInvalid={!!errors.signLabel}
                        onChange={handleChange}
                      />
                      {errors.signLabel && (
                        <Form.Text className="text-danger">
                          {errors.signLabel}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicFName">
                      <Form.Label>Contact</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Contact"
                        name="contactInfo"
                        value={fieldsData.contactInfo || ""}
                        isInvalid={!!errors.contactInfo}
                        onChange={handleChange}
                      />
                      {errors.contactInfo && (
                        <Form.Text className="text-danger">
                          {errors.contactInfo}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formGridNotes">
                      <Form.Label>Notes Section</Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "Heading",
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                            "BlockQuote",
                            "List",
                            "Indent",
                            "Table",
                          ],
                        }}
                        data={notes}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setNotes(data);
                        }}
                      />
                      {errors.notes && (
                        <Form.Text className="text-danger">
                          {errors.notes}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formGridStatus">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        name="status"
                        as="select"
                        value={fieldsData.status}
                        onChange={handleChange}
                      >
                        <option value="">Select..</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Col>
                <Col lg={8}>
                  <Row
                    className={
                      spinner
                        ? "border border-dark m-3 h-75"
                        : "m-3 border border-dark"
                    }
                  >
                    {spinner ? (
                      <div className="overlay-content1 d-flex align-items-center justify-content-center">
                        <div
                          className="spinner-border text-dark"
                          style={{ width: "3rem", height: "3rem" }}
                          role="status"
                        >
                          <span className="sr-only"></span>
                        </div>
                        <span className="d-block text-dark  ml-2">
                          <b>Loading content...</b>
                        </span>
                      </div>
                    ) : (
                      <MapLoad
                        height={"59vh"}
                        id={"mainMapView"}
                        options={mapOption}
                        onMapLoad={(map) => {
                          //map.setCenter(mapLatLng)
                          mapInit(map);
                          var input =
                            document.getElementById("auto-place-edit");
                          var autocomplete =
                            new window.google.maps.places.Autocomplete(input, {
                              types: ["geocode"],
                            });
                          autocomplete.addListener("place_changed", () => {
                            const place = autocomplete.getPlace();
                            if (!place.geometry) {
                              // User entered the name of a Place that was not suggested and
                              // pressed the Enter key, or the Place Details request failed.
                              toast.error(
                                "No details available for input: " + place.name
                              );

                              return;
                            }
                            setLocationM(place.formatted_address);
                            map.panTo(place.geometry.location);
                          });

                          map.addListener("click", (e) => {
                            new window.google.maps.Geocoder().geocode(
                              { latLng: e.latLng },
                              function (results, status) {
                                addOtherLocation(
                                  map,
                                  e.latLng,
                                  results[0].formatted_address
                                );
                              }
                            );
                          });
                        }}
                      />
                    )}
                  </Row>
                </Col>
              </Row>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MapEditComponent;
