import React, { useCallback, useEffect } from 'react';

function MapLoad(props) {

  const onScriptLoad = useCallback(async () => {
    const map = new window.google.maps.Map(
      document.getElementById(props.id),
      props.options);
    props.onMapLoad(map)
  },[props])

  useEffect(() => {
    onScriptLoad()
  }, [onScriptLoad])
  
  return (
    <div style={{ height: props.height, width: '100%' }} id={props.id} />
  );
}

export default MapLoad;
