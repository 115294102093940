import React, { useEffect, useState } from 'react';
import styles from './SignupComponent.module.css';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import { userService } from './../../Services/userServices'
import StorageService from '../../storage/StorageService';
import { toast } from 'react-toastify';

const SignupComponent = (props) => {
  const initialValues = {                   // type all the fields you need
    firstName: '',
    lastName: '',
    email: '',
    userType: 'Crew',
    password: '',
    cpassword: ''
  };

  const [fielsData, setValues] = useState(initialValues);
  const [errors, setErrorValues] = useState(initialValues);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    document.title = "Map App - Sign-up"
    if (StorageService.getLogin() && StorageService.getLogin() !== null) {
      props.history.push('/home')
    }
  })

  const handleValidation = () => {
    let fields = fielsData;
    let errors = {};
    let formIsValid = true;

    //Name
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "First name cannot be empty";
    }

    if ((typeof fields["firstName"] !== "undefined") && (fields["firstName"].length !== 0)) {
      if (!fields["firstName"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["firstName"] = "Enter only letters";
      }
    }

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "Last name cannot be empty";
    }

    if ((typeof fields["lastName"] !== "undefined") && (fields["lastName"].length !== 0)) {
      if (!fields["lastName"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["lastName"] = "Enter only letters";
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }

    if ((typeof fields["email"] !== "undefined") && (fields["email"].length !== 0)) {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    if (!fields["userType"]) {
      formIsValid = false;
      errors["userType"] = "User type cannot be empty";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    }

    if (!fields["cpassword"]) {
      formIsValid = false;
      errors["cpassword"] = "Confirm password cannot be empty";
    }

    if (fields["password"] !== fields["cpassword"]) {
      formIsValid = false;
      errors["cpassword"] = "Password and confirm password does not match";
    }

    setFormIsValid(formIsValid)
    setErrorValues(errors)
    return formIsValid;
  }

  const handleChange = (e) => {
    setValues({ ...fielsData, [e.target.name]: e.target.value });
  };

  const handleUserInput = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      delete fielsData.cpassword
      userService.signup(fielsData, props.match.params.id).then(res => {
        if (res.flag === 'success') {
          toast.success('User registered successfully')
          props.userSignup(res)
          props.history.push('/login')
        }
      })
        .catch(err => toast.error(err))
    }

  }
  return (
    <div className={styles.SignupComponent}>
      <Container>
        <Row>
          <Col className="p-3 mt-5 form-outer" md={{ span: 4, offset: 4 }}>
            <div className="form-heading border-bottom">
              <h2>Signup</h2>
            </div>
            <Form className="pt-2" noValidate validated={formIsValid} onSubmit={handleUserInput}>

              <Form.Group className="mb-3" controlId="formBasicFName">
                <Form.Label>Fist Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={fielsData.firstName || ''}
                  isInvalid={!!errors.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && <Form.Text className="text-danger">
                  {errors.firstName}
                </Form.Text>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicLName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={fielsData.lastName || ''}
                  isInvalid={!!errors.lastName}
                  onChange={handleChange}
                />
                {errors.lastName && <Form.Text className="text-danger">
                  {errors.lastName}
                </Form.Text>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={fielsData.email || ''}
                  isInvalid={!!errors.email}
                  onChange={handleChange}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
                {errors.email && <Form.Text className="text-danger">
                  {errors.email}
                </Form.Text>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridState">
                <Form.Label>User Type</Form.Label>
                <Form.Control
                  disabled
                  name="userType"
                  as="select"
                  value={fielsData.userType || ''}
                  onChange={handleChange}
                >
                  <option value="" >Select..</option>
                  <option value="Manager" >Manager</option>
                  <option value="Crew">Crew</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  name="password"
                  value={fielsData.password || ''}
                  isInvalid={!!errors.password}
                  onChange={handleChange}
                />
                {errors.password && <Form.Text className="text-danger">
                  {errors.password}
                </Form.Text>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="cpassword"
                  value={fielsData.cpassword || ''}
                  isInvalid={!!errors.cpassword}
                  onChange={handleChange}
                />
                {errors.cpassword && <Form.Text className="text-danger">
                  {errors.cpassword}
                </Form.Text>}
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default SignupComponent;
