import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import styles from './ProjectsComponent.module.css';
import { userService } from '../../Services/userServices';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import StorageService from '../../storage/StorageService';

const EditProjectsComponent = (props) => {
    const initialValues = {                   // type all the fields you need
        projectName: '',
        status: false
    };

    const [fielsData, setValues] = useState(initialValues);
    const [projectData, setProject] = useState([])
    const [errors, setErrorValues] = useState(initialValues);
    const [formIsValid, setFormIsValid] = useState(false);
    const [overLay, setOverLay] = useState(false)

    useEffect(() => {
        setProject(props.location.state.details)
        setValues({
            projectName: projectData.projectName,
            status: projectData.status,
        })
    }, [props, projectData])

    const handleValidation = () => {
        let fields = fielsData;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["projectName"]) {
            formIsValid = false;
            errors["projectName"] = "Project name cannot be empty";
        }

        setFormIsValid(formIsValid)
        setErrorValues(errors)
        return formIsValid;
    }

    const handleChange = (e) => {
        setValues({ ...fielsData, [e.target.name]: e.target.value });
    };

    const handleUserInput = async (event) => {
        setOverLay(userService.overLay(true))
        event.preventDefault();
        try {
            if (handleValidation()) {
                const data = {
                    projectName: fielsData.projectName,
                    status: fielsData.status,
                    createdBy: StorageService.getLogin().profile.userId
                };
                await userService.httpApi('PATCH', `admin/projects/${projectData._id}`, JSON.stringify(data));
                toast.success('Project added successfully');
                setTimeout(() => {
                    props.history.push('/projects')
                }, 800)
            } else {
                setOverLay(userService.overLay(false))
            }
        } catch (error) {
            toast.warn(error);
            setOverLay(userService.overLay(false))
        }
    }
    return (
        <div className={styles.ProjectsComponent} >
            {overLay}
            <div className={styles.LoginComponent}>
                <Container>
                    <Row>
                        <Col className="p-3 mt-5 form-outer">
                            <Row className="border-bottom">
                                <Col>
                                    <div className="from-heading">
                                        <h2>Edit Project</h2>
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        className="float-right"
                                        as={Link}
                                        to="/projects"
                                        variant="primary"
                                    >
                                        <ArrowBackIos />
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                            <Form className="mt-3" noValidate validated={formIsValid} onSubmit={handleUserInput}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicFName">
                                            <Form.Label>Project Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Project Name"
                                                name="projectName"
                                                value={fielsData.projectName || ''}
                                                isInvalid={!!errors.projectName}
                                                onChange={handleChange}
                                            />
                                            {errors.projectName && <Form.Text className="text-danger">
                                                {errors.projectName}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formGridStatus">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control
                                                name="status"
                                                as="select"
                                                value={fielsData.status}
                                                onChange={handleChange}
                                            >
                                                <option value="" >Select..</option>
                                                <option value={true} >Active</option>
                                                <option value={false}>Inactive</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    );
}

export default EditProjectsComponent;