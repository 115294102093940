import React, { useState } from 'react';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import styles from './UsersComponent.module.css';
import { userService } from '../../Services/userServices';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { connect } from 'react-redux';

const AddUserComponent = (props) => {
    const { userType } = props
    const initialValues = {                   // type all the fields you need
        firstName: '',
        lastName: '',
        email: '',
        userType: userType === 'Manager' ? 'Crew' : '',
        status: 'pending',
        password: '',
        cpassword: ''
    };

    const [fielsData, setValues] = useState(initialValues);
    const [errors, setErrorValues] = useState(initialValues);
    const [formIsValid, setFormIsValid] = useState(false);
    const [overLay, setOverLay] = useState(false)

    const handleValidation = () => {
        let fields = fielsData;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "First name cannot be empty";
        }

        if ((typeof fields["firstName"] !== "undefined") && (fields["firstName"].length !== 0)) {
            if (!fields["firstName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["firstName"] = "Enter only letters";
            }
        }

        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Last name cannot be empty";
        }

        if ((typeof fields["lastName"] !== "undefined") && (fields["lastName"].length !== 0)) {
            if (!fields["lastName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["lastName"] = "Enter only letters";
            }
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }

        if ((typeof fields["email"] !== "undefined") && (fields["email"].length !== 0)) {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        if (!fields["userType"]) {
            formIsValid = false;
            errors["userType"] = "User type cannot be empty";
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Password cannot be empty";
        }

        if (!fields["cpassword"]) {
            formIsValid = false;
            errors["cpassword"] = "Confirm password cannot be empty";
        }

        if (fields["password"] !== fields["cpassword"]) {
            formIsValid = false;
            errors["cpassword"] = "Password and confirm password does not match";
        }

        setFormIsValid(formIsValid)
        setErrorValues(errors)
        return formIsValid;
    }

    const handleChange = (e) => {
        setValues({ ...fielsData, [e.target.name]: e.target.value });
    };

    const handleUserInput = (event) => {
        setOverLay(userService.overLay(true))
        event.preventDefault();
        if (handleValidation()) {
            delete fielsData.cpassword
            userService.httpApi('POST', `admin/users`, JSON.stringify(fielsData))
                .then(res => {
                    toast.success('User added successfully')
                    setTimeout(() => {
                        props.history.push('/users')
                    }, 800)
                })
                .catch(err => {
                    toast.error(err);
                    setOverLay(userService.overLay(false))
                })
        } else {
            setOverLay(userService.overLay(false))
        }
    }
    return (
        <div className={styles.AddUserComponent} >
            {overLay}
            <div className={styles.LoginComponent}>
                <Container>
                    <Row>
                        <Col className="p-3 mt-5 form-outer">
                            <Row className="border-bottom">
                                <Col>
                                    <div className="from-heading">
                                        <h2>Add User</h2>
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        className="float-right"
                                        as={Link}
                                        to="/users"
                                        variant="primary"
                                    >
                                        <ArrowBackIosIcon />
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                            <Form className="mt-3" noValidate validated={formIsValid} onSubmit={handleUserInput}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicFName">
                                            <Form.Label>Fist Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name"
                                                name="firstName"
                                                value={fielsData.firstName || ''}
                                                isInvalid={!!errors.firstName}
                                                onChange={handleChange}
                                            />
                                            {errors.firstName && <Form.Text className="text-danger">
                                                {errors.firstName}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicLName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Last Name"
                                                name="lastName"
                                                value={fielsData.lastName || ''}
                                                isInvalid={!!errors.lastName}
                                                onChange={handleChange}
                                            />
                                            {errors.lastName && <Form.Text className="text-danger">
                                                {errors.lastName}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email"
                                                name="email"
                                                value={fielsData.email || ''}
                                                isInvalid={!!errors.email}
                                                onChange={handleChange}
                                            />
                                            <Form.Text className="text-muted">
                                                We'll never share your email with anyone else.
                                            </Form.Text>
                                            {errors.email && <Form.Text className="text-danger">
                                                {errors.email}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formGridType">
                                                    <Form.Label>User Type</Form.Label>
                                                    <Form.Control
                                                        disabled={userType === 'Manager'}
                                                        name="userType"
                                                        as="select"
                                                        value={fielsData.userType || ''}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="" >Select..</option>
                                                        <option value="Manager" >Manager</option>
                                                        <option value="Crew">Crew</option>
                                                    </Form.Control>
                                                    {userType !== 'Manager' && errors.userType && <Form.Text className="text-danger">
                                                        {errors.userType}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formGridStatus">
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Control
                                                        name="status"
                                                        as="select"
                                                        value={fielsData.status || ''}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="" >Select..</option>
                                                        <option value="pending">Pending</option>
                                                        <option value="active" >Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Confirm Password"
                                                name="password"
                                                value={fielsData.password || ''}
                                                isInvalid={!!errors.password}
                                                onChange={handleChange}
                                            />
                                            {errors.password && <Form.Text className="text-danger">
                                                {errors.password}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicCPassword">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                name="cpassword"
                                                value={fielsData.cpassword || ''}
                                                isInvalid={!!errors.cpassword}
                                                onChange={handleChange}
                                            />
                                            {errors.cpassword && <Form.Text className="text-danger">
                                                {errors.cpassword}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    );
}

const mapStateToProps = state => {
    return {
        userType: state.userType
    }
}

export default connect(mapStateToProps)(AddUserComponent);