import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import styles from './UsersComponent.module.css';
import { userService } from '../../Services/userServices';
import { toast } from 'react-toastify';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import StorageService from '../../storage/StorageService';

const EditUserComponent = (props) => {
    const { history } = props
    const initialValues = {                   // type all the fields you need
        firstName: '',
        lastName: '',
        email: '',
        userType: '',
        status: ''
    };
    const { userType } = props;
    const [fielsData, setValues] = useState(initialValues);
    const [userData, setUserData] = useState([])
    const [errors, setErrorValues] = useState(initialValues);
    const [formIsValid, setFormIsValid] = useState(false);
    const [overLay, setOverLay] = useState(false)

    useEffect(() => {
        setUserData(props.location.state.details)
        setValues({
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            userType: userData.userType,
            status: userData.status,
        })
    }, [props, userData])

    const handleValidation = () => {
        let fields = fielsData;
        let errors = {};
        let formIsValid = true;

        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "First name cannot be empty";
        }

        if ((typeof fields["firstName"] !== "undefined") && (fields["firstName"].length !== 0)) {
            if (!fields["firstName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["firstName"] = "Enter only letters";
            }
        }

        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Last name cannot be empty";
        }

        if ((typeof fields["lastName"] !== "undefined") && (fields["lastName"].length !== 0)) {
            if (!fields["lastName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["lastName"] = "Enter only letters";
            }
        }

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }

        if ((typeof fields["email"] !== "undefined") && (fields["email"].length !== 0)) {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        if (!fields["userType"]) {
            formIsValid = false;
            errors["userType"] = "User type cannot be empty";
        }

        setFormIsValid(formIsValid)
        setErrorValues(errors)
        return formIsValid;
    }

    const handleChange = (e) => {
        setValues({ ...fielsData, [e.target.name]: e.target.value });
    };

    const handleUserInput = (event) => {
        setOverLay(userService.overLay(true))
        event.preventDefault();
        if (handleValidation()) {
            userService.httpApi('PATCH', `admin/users/${userData._id}`, JSON.stringify(fielsData))
                .then(res => {
                    if (userData._id === StorageService.getLogin().profile.userId) {
                        props.userLogin(res)
                        userService.getUser(userData._id)
                    }
                    toast.success('User updated successfully')
                    if (userType !== 'Crew') {
                        setTimeout(() => {
                            props.history.push('/users')
                        }, 800)
                    } else {
                        setTimeout(() => {
                            props.history.push('/projects')
                        }, 800)
                    }
                })
                .catch(err => { toast.error(err); setOverLay(userService.overLay(false)) })
        } else {
            setOverLay(userService.overLay(false))
        }
    }

    const goBack = () => {
        history.goBack()
    }

    return (
        <div className={styles.AddUserComponent} >
            {overLay}
            <div className={styles.LoginComponent}>
                <Container>
                    <Row>
                        <Col className="p-3 mt-5 form-outer">
                            <Row className="border-bottom">
                                <Col>
                                    <div className="from-heading">
                                        <h2>Edit User</h2>
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        className="float-right"
                                        variant="primary"
                                        onClick={() => goBack()}
                                    >
                                        <ArrowBackIosIcon />
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                            <Form className="mt-3" noValidate validated={formIsValid} onSubmit={handleUserInput}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicFName">
                                            <Form.Label>Fist Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name"
                                                name="firstName"
                                                value={fielsData.firstName || ''}
                                                isInvalid={!!errors.firstName}
                                                onChange={handleChange}
                                            />
                                            {errors.firstName && <Form.Text className="text-danger">
                                                {errors.firstName}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicLName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Last Name"
                                                name="lastName"
                                                value={fielsData.lastName || ''}
                                                isInvalid={!!errors.lastName}
                                                onChange={handleChange}
                                            />
                                            {errors.lastName && <Form.Text className="text-danger">
                                                {errors.lastName}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email"
                                                name="email"
                                                value={fielsData.email || ''}
                                                isInvalid={!!errors.email}
                                                onChange={handleChange}
                                                readOnly
                                            />
                                            <Form.Text className="text-muted">
                                                We'll never share your email with anyone else.
                                            </Form.Text>
                                            {errors.email && <Form.Text className="text-danger">
                                                {errors.email}
                                            </Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        {userData.userType !== 'Admin' &&
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="formGridType">
                                                        <Form.Label>User Type</Form.Label>
                                                        <Form.Control
                                                            disabled={userType === 'Manager' || userData._id === StorageService.getLogin().profile.userId ? 'disabled' : ''}
                                                            name="userType"
                                                            as="select"
                                                            value={fielsData.userType || ''}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="" >Select..</option>
                                                            <option value="Manager" >Manager</option>
                                                            <option value="Crew">Crew</option>
                                                        </Form.Control>
                                                        {errors.userType && <Form.Text className="text-danger">
                                                            {errors.userType}
                                                        </Form.Text>}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="formGridStatus">
                                                        <Form.Label>Status</Form.Label>
                                                        <Form.Control
                                                            disabled={userType !== 'Admin' && userData._id === StorageService.getLogin().profile.userId ? 'disabled' : ''}
                                                            name="status"
                                                            as="select"
                                                            value={fielsData.status || ''}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="" >Select..</option>
                                                            <option value="pending">Pending</option>
                                                            <option value="active" >Active</option>
                                                            <option value="inactive">Inactive</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    );
}

export default EditUserComponent;