import { Table, Button, Form } from "react-bootstrap";

export const Tables = { drawTable, tableButton, tableCheckbox };

function drawTable(columns, rows) {
  return (
    <Table className="mt-3 table-responsive-lg" striped bordered hover>
      <thead>
        <tr>{drawColumns(columns)}</tr>
      </thead>
      <tbody>{drawRows(rows)}</tbody>
    </Table>
  );
}

const drawColumns = (columns) => {
  return columns.map((res, i) => <th key={i}>{res}</th>);
};

const drawRows = (rows) => {
  return rows.map((res, i) => {
    return <tr key={i}>{drawRowsColumns(res)}</tr>;
  });
};

const drawRowsColumns = (col) => {
  const array = Object.values(col);
  return array.map((res, i) => <td key={i}>{res}</td>);
};

function tableButton(data, fun) {
  return (
    <Button
      key={data.index && data.index}
      className="mr-2 pt-0 pb-0"
      size={data.size}
      variant={data.color}
      onClick={fun}
    >
      {data.icon ? data.icon : data.name}
    </Button>
  );
}

function tableCheckbox(data, fun) {
  return (
    <Form.Check
      type={"checkbox"}
      data-index-number={data._id}
      className={"checkbox"}
      onClick={fun}
    ></Form.Check>
  );
}
