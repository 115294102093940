import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import styles from "./TeamsComponent.module.css";
import { userService } from "../../Services/userServices";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import MyPagination from "../../Services/pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import StorageService from "../../storage/StorageService";
import { Tables } from "../Helpers/Tables";

const TeamsComponent = (props) => {
  let idsForDeleteData = useRef([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [key, setKey] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [overLay, setOverLay] = useState(false);
  const [table, setTable] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [ids, setIds] = useState(idsForDeleteData.current.length);
  const [checkbox] = useState(
    <Form.Check
      type={"checkbox"}
      label="All"
      className={"checkbox-1"}
      onClick={(e) => checkedAll(e)}
    ></Form.Check>
  );
  const [tableHead] = useState([
    checkbox,
    "#",
    "Team Name",
    "Project",
    "Manager",
    "Crew",
    "Maps",
    "Actions",
  ]);
  const [icons] = useState({
    Edit: <EditIcon />,
    Delete: <DeleteForeverIcon />,
  });
  const [buttonColor] = useState({
    Edit: "primary",
    Delete: "danger",
    Invite: "dark",
  });

  const checkedAll = (source) => {
    const checkboxes = document.getElementsByClassName("checkbox");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].childNodes[0].checked = source.target.checked;
      if (source.target.checked) {
        setShowDelete(true);
        idsForDeleteData.current[i] =
          checkboxes[i].childNodes[0].getAttribute("data-index-number");
      } else {
        setShowDelete(false);
        idsForDeleteData.current = [];
      }
    }
    setIds(idsForDeleteData.current.length);
  };

  const checkedOneByOne = useCallback(
    (e) => {
      var id = e.target.getAttribute("data-index-number");
      if (!e.target.checked) {
        var filtered = idsForDeleteData.current.filter(function (
          value,
          index,
          arr
        ) {
          return value !== id;
        });
        idsForDeleteData.current = filtered;
        if (totalCount > idsForDeleteData.current.length) {
          const checkboxF = document.getElementsByClassName("checkbox-1")[0];
          checkboxF.childNodes[0].checked = e.target.checked;
        }
        if (idsForDeleteData.current.length === 0) setShowDelete(false);
        setIds(idsForDeleteData.current.length);
      } else {
        idsForDeleteData.current = [...idsForDeleteData.current, id];
        if (totalCount === idsForDeleteData.current.length) {
          const checkboxF = document.getElementsByClassName("checkbox-1")[0];
          checkboxF.childNodes[0].checked = e.target.checked;
        }
        if (idsForDeleteData.current.length > 0) setShowDelete(true);
        setIds(idsForDeleteData.current.length);
      }
    },
    [totalCount]
  );

  const teamCrews = useCallback(
    (_id) => {
      setOverLay(userService.overLay(true));
      userService
        .httpApi("GET", `team/crews/${_id}`, null)
        .then((res) => {
          props.history.push({
            pathname: "/teams/crews",
            search: "",
            state: { details: res },
          });
        })
        .catch((err) => console.log(err));
    },
    [props.history]
  );

  const teamMaps = useCallback(
    (_id) => {
      setOverLay(userService.overLay(true));
      userService
        .httpApi("GET", `team/maps/${_id}`, null)
        .then((res) => {
          props.history.push({
            pathname: "/teams/maps",
            search: "",
            state: { details: res },
          });
        })
        .catch((err) => console.log(err));
    },
    [props.history]
  );

  const teamManager = useCallback(
    (_id) => {
      setOverLay(userService.overLay(true));
      userService
        .httpApi("GET", `admin/users/${_id}`, null)
        .then((res) => {
          props.history.push({
            pathname: "/users/view",
            search: "",
            state: { details: res },
          });
        })
        .catch((err) => console.log(err));
    },
    [props.history]
  );

  const teamProject = useCallback(
    (_id) => {
      setOverLay(userService.overLay(true));
      userService
        .httpApi("GET", `team/projects/${_id}`, null)
        .then((res) => {
          props.history.push({
            pathname: "/teams/project",
            search: "",
            state: { details: res },
          });
        })
        .catch((err) => console.log(err));
    },
    [props.history]
  );

  const handleEdit = useCallback(
    (_id) => {
      props.history.push({
        pathname: "/teams/edit",
        search: "",
        state: { id: _id },
      });
    },
    [props.history]
  );

  const drawTable = useCallback(
    (resp, i) => {
      return {
        checkbox: Tables.tableCheckbox({ _id: resp._id }, function (e) {
          return checkedOneByOne(e);
        }),
        sno: (currPage - 1) * perPage + i + 1,
        name: resp.teamName,
        project: Tables.tableButton(
          { name: "Project", color: "primary", size: "sm" },
          function () {
            return teamProject(resp._id);
          }
        ),
        manager: Tables.tableButton(
          {
            name:
              resp.manager[0]?.userId.firstName +
              " " +
              resp.manager[0]?.userId.lastName,
            color: "info",
            size: "sm",
          },
          function () {
            return teamManager(resp.manager[0]?.userId._id);
          }
        ),
        crew: Tables.tableButton(
          { name: "Crew", color: "primary", size: "sm" },
          function () {
            return teamCrews(resp._id);
          }
        ),
        maps: Tables.tableButton(
          { name: "Maps", color: "success", size: "sm" },
          function () {
            return teamMaps(resp._id);
          }
        ),
        actions: ["Edit", "Delete", "Invite"].map((res, i) => {
          return Tables.tableButton(
            {
              name: res,
              color: buttonColor[res],
              size: "sm",
              index: i,
              icon: icons[res],
            },
            function () {
              switch (res) {
                case "Edit":
                  return handleEdit(resp._id);

                case "Delete":
                  return handleDelete(resp._id);

                case "Invite":
                  return handleInvite(resp._id);

                default:
                  break;
              }
            }
          );
        }),
      };
    },
    [
      buttonColor,
      checkedOneByOne,
      currPage,
      handleEdit,
      icons,
      perPage,
      teamCrews,
      teamManager,
      teamMaps,
      teamProject,
    ]
  );

  const drawTableManager = useCallback(
    (resp, i) => {
      return {
        sno: (currPage - 1) * perPage + i + 1,
        name: resp.teamId.teamName,
        project: Tables.tableButton(
          { name: "Project", color: "primary", size: "sm" },
          function () {
            return teamProject(resp.teamId._id);
          }
        ),
        manager: Tables.tableButton(
          {
            name: resp.userId.firstName + " " + resp.userId.lastName,
            color: "info",
            size: "sm",
          },
          function () {
            return teamManager(resp.userId._id);
          }
        ),
        crew: Tables.tableButton(
          { name: "Crew", color: "primary", size: "sm" },
          function () {
            return teamCrews(resp.teamId._id);
          }
        ),
        maps: Tables.tableButton(
          { name: "Maps", color: "success", size: "sm" },
          function () {
            return teamMaps(resp.teamId._id);
          }
        ),
        actions: ["Edit", "Delete", "Invite"].map((res, i) => {
          return Tables.tableButton(
            {
              name: res,
              color: buttonColor[res],
              size: "sm",
              index: i,
              icon: icons[res],
            },
            function () {
              switch (res) {
                case "Edit":
                  return handleEdit(resp.teamId._id);

                case "Delete":
                  return handleDelete(resp.teamId._id);

                case "Invite":
                  return handleInvite(resp.teamId._id);

                default:
                  break;
              }
            }
          );
        }),
      };
    },
    [
      buttonColor,
      currPage,
      handleEdit,
      icons,
      perPage,
      teamCrews,
      teamManager,
      teamMaps,
      teamProject,
    ]
  );

  const getTeams = useCallback(
    async (page) => {
      setCurrPage(page);
      await userService
        .httpApi("GET", `admin/teams/${page}/${perPage}`, null)
        .then((res) => {
          setTotalCount(res.totalCount);
          if (res.role === "Admin") {
            res.teams.map(
              (obj, i) =>
                (res.teams[i]["manager"] = res.teamManager.filter(
                  (res) => res.teamId === obj._id
                ))
            );
            const data = res.teams.map((resp, i) => drawTable(resp, i));
            setTable(Tables.drawTable(tableHead, data));
            setTotalPage(Math.ceil(res.totalCount / perPage));
          } else {
            const data = res.teams.map((resp, i) => drawTableManager(resp, i));
            setTable(Tables.drawTable(tableHead, data));
            setTotalPage(Math.ceil(res.totalCount / perPage));
          }
          setSpinner(false);
          setOverLay(userService.overLay(false));
        })
        .catch((error) => {
          setSpinner(false);
          setOverLay(userService.overLay(false));
          if (error === "Token Expired") {
            toast.warn(error);
            StorageService.clearLogin();
          } else if (error === "Login Session Expired") {
            toast.warn(error);
            StorageService.clearLogin();
            props.history.push(`/login`);
          } else {
            toast.warn(error);
          }
        });
    },
    [drawTable, drawTableManager, perPage, props.history, tableHead]
  );

  useEffect(() => {
    document.title = "Map App - Teams";
    setOverLay(userService.overLay(true));
    setSpinner(true);
    getTeams(currPage);
    setIds(0);
    idsForDeleteData.current = [];
    setShowDelete(false);
  }, [perPage, key, getTeams, currPage]);

  const perPageCount = (e) => {
    setCurrPage(1);
    setPerPage(e.target.value);
  };

  const handleDelete = (_id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        html: "You will not be able to recover this team",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then(async (result) => {
        if (result.value) {
          await userService.httpApi("DELETE", `admin/teams/${_id}`, null);
          setKey(Math.random());
          Swal.fire("Deleted!", "Team has been deleted.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Team is safe &#128526;", "error");
        }
      });
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const handleInvite = (_id) => {
    const url = `${window.location.origin.toString()}/sign-up/${_id}`;
    if (navigator.clipboard.writeText(url)) {
      toast.dark("Copied", { position: toast.POSITION.BOTTOM_CENTER });
    }
  };

  const deleteMultipleTableData = () => {
    const _ids = JSON.stringify(idsForDeleteData.current);
    Swal.fire({
      title: "Are you sure?",
      html: "You will not be able to recover this teams",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.value) {
        await userService.httpApi(
          "DELETE",
          `admin/teams`,
          JSON.stringify({ arr: _ids })
        );
        setKey(Math.random());
        Swal.fire("Deleted!", "Teams has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Teams is safe &#128526;", "error");
      }
    });
  };

  return (
    <div className={styles.TeamsComponent}>
      {overLay}
      <Container>
        <Row>
          <Col className="p-3 mb-5 mt-5 form-outer">
            <Row className="border-bottom">
              <Col>
                <div className="from-heading">
                  {showDelete ? (
                    <h2>
                      {ids > 1
                        ? `${ids} Records Selected`
                        : `${ids} Record Selected`}
                    </h2>
                  ) : (
                    <h2>All Teams</h2>
                  )}
                </div>
              </Col>
              <Col>
                {showDelete ? (
                  <div className="float-right ">
                    <Button
                      title="DELETE"
                      id="deleteBtn"
                      variant="danger"
                      onClick={deleteMultipleTableData}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="float-right"
                    as={Link}
                    to="/teams/add"
                    variant="primary"
                  >
                    <AddIcon />
                    Add Team
                  </Button>
                )}
              </Col>
            </Row>
            {spinner ? "Loading..." : table}
            <Row>
              <Col>
                <Form.Control
                  className="w-auto"
                  name="userType"
                  as="select"
                  value={perPage || ""}
                  onChange={perPageCount}
                  style={{ marginTop: "15px" }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Form.Control>
              </Col>
              <Col>
                <MyPagination
                  totPages={totalPage}
                  currentPage={currPage}
                  pageClicked={(e) => getTeams(e)}
                ></MyPagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TeamsComponent;
