import React, { useCallback, useEffect, useState } from 'react';
import { Container, Button, Col, Row } from 'react-bootstrap';
import styles from './TeamsComponent.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import { Tables } from '../Helpers/Tables';

const TeamCrewsComponent = (props) => {
    const [userData, setUserData] = useState([])
    const [table, setTable] = useState('');
    const [tableHead] = useState(['#', 'Crew Name', 'Email', 'Status']);

    const drawTable = useCallback((resp, i) => {
        return {
            sno: i + 1,
            name: resp.firstName + ' ' + resp.lastName,
            email: resp.email,
            status: resp.status
        }
    }, [])

    useEffect(() => {
        setUserData(props.location.state.details)
        const data = props.location.state.details.map((resp, i) => drawTable(resp, i))
        setTable(Tables.drawTable(tableHead, data))
    }, [drawTable, props, tableHead, userData])


    return (
        <div className={styles.TeamsComponent} >
            <Container>
                <Row>
                    <Col className="p-3 mt-5 form-outer">
                        <Row className="border-bottom">
                            <Col>
                                <div className="from-heading">
                                    <h2>All Team's Crew</h2>
                                </div>
                            </Col>
                            <Col>
                                <Button
                                    className="float-right"
                                    as={Link}
                                    to="/teams"
                                    variant="primary"
                                >
                                    <ArrowBackIosIcon />
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        {table}
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

export default TeamCrewsComponent;