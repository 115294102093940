import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./Containers/LoginContainer";
import SignupContainer from "./Containers/SignupContainer";
import Header from "./Containers/HeaderContainer";
import PageNotFound from "./components/Errors/PageNotFound";
import "./App.css";
import { PrivateRoute } from "./protected/PrivateRoute";
import { ProtectedRoute } from "./protected/ProtectedRoute";
import Dashboard from "./components/DashboardComponent/DashboardComponent";
import Users from "./components/UsersComponent/UsersComponent";
import MapList from "./components/MapsComponent/MapList";
import MapEditComponent from "./components/MapsComponent/MapEditComponent";
import MapViewComponent from "./components/MapsComponent/MapViewComponent";
import AddUserComponent from "./components/UsersComponent/AddUserComponent";
import EditUserComponent from "./Containers/UserEditContainer";
import ViewUserComponent from "./components/UsersComponent/ViewUserComponent";
import ProjectsComponent from "./components/ProjectsComponent/ProjectsComponent";
import AddProjectsComponent from "./components/ProjectsComponent/AddProjectsComponent";
import EditTeamsComponent from "./components/TeamsComponent/EditTeamsComponent";
import EditProjectsComponent from "./components/ProjectsComponent/EditProjectsComponent";
import TeamsComponent from "./components/TeamsComponent/TeamsComponent";
import AddTeamsComponent from "./components/TeamsComponent/AddTeamsComponent";
import TeamCrewsComponent from "./components/TeamsComponent/TeamCrewsComponent";
import TeamManagersComponent from "./components/TeamsComponent/TeamManagersComponent";
import TeamProjectsComponent from "./components/TeamsComponent/TeamProjectsComponent";
import TeamMapsComponent from "./components/TeamsComponent/TeamMapsComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Layout/Footer";
import MapAddComponent from "./components/MapsComponent/MapAddComponent";

function App() {
  return (
    <>
      <Router>
        <Header />
        <ToastContainer />
        <div id="main-wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 App">
                  <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route path="/sign-up/:id" component={SignupContainer} />
                    <PrivateRoute path="/home" component={Dashboard} />
                    <ProtectedRoute exact path="/users" component={Users} />
                    <ProtectedRoute
                      path="/users/add"
                      component={AddUserComponent}
                    />
                    <PrivateRoute
                      path="/users/view"
                      component={ViewUserComponent}
                    />
                    <PrivateRoute
                      path="/users/edit"
                      component={EditUserComponent}
                    />
                    <PrivateRoute
                      exact
                      path="/projects"
                      component={ProjectsComponent}
                    />
                    <ProtectedRoute
                      path="/projects/add"
                      component={AddProjectsComponent}
                    />
                    <ProtectedRoute
                      path="/projects/edit"
                      component={EditProjectsComponent}
                    />
                    <ProtectedRoute
                      exact
                      path="/teams"
                      component={TeamsComponent}
                    />
                    <ProtectedRoute
                      path="/teams/add"
                      component={AddTeamsComponent}
                    />
                    <ProtectedRoute
                      path="/teams/edit"
                      component={EditTeamsComponent}
                    />
                    <ProtectedRoute
                      path="/teams/crews"
                      component={TeamCrewsComponent}
                    />
                    <PrivateRoute
                      path="/teams/maps"
                      component={TeamMapsComponent}
                    />
                    <ProtectedRoute
                      path="/teams/manager"
                      component={TeamManagersComponent}
                    />
                    <ProtectedRoute
                      path="/teams/project"
                      component={TeamProjectsComponent}
                    />
                    <ProtectedRoute exact path="/maps" component={MapList} />
                    <ProtectedRoute
                      path="/maps/add"
                      component={MapAddComponent}
                    />
                    <ProtectedRoute
                      path="/maps/edit"
                      component={MapEditComponent}
                    />
                    <PrivateRoute
                      path="/maps/view"
                      component={MapViewComponent}
                    />
                    <Route path="*" exact={true} component={PageNotFound} />
                    <Redirect to="/" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
