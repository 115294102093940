import React, { useEffect, useState } from "react";
import logo2 from "./../../assets/images/earth-png-25621.png";
import styles from "./HeaderComponent.module.css";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { userService } from "../../Services/userServices";
import StorageService from "../../storage/StorageService";
import { toast } from "react-toastify";

export default function Header(props) {
  const { isLogged, userType } = props;
  const history = useHistory();
  const { location } = history;
  const fullPath = location.pathname;
  const activePath = fullPath.split("/");
  const [userInfo, setUserInfo] = useState({
    userName: "",
    userType: "",
  });

  useEffect(() => {
    if (StorageService.getLogin() && StorageService.getLogin() !== null) {
      setTimeout(() => {
        setUserInfo({
          userName:
            StorageService.getLogin() !== null
              ? StorageService.getLogin().profile.firstName
              : "",
          userType:
            StorageService.getLogin() !== null
              ? StorageService.getLogin().profile.userType
              : "",
        });
      }, 300);
    }
  }, [userInfo, isLogged]);

  const logout = () => {
    setUserInfo({
      userName: "",
      userType: "",
    });
    userService.logout();
    toast.success("Logout Successfully");
    props.logout();
    history.push("/login");
  };

  const handleView = () => {
    const _id = StorageService.getLogin().profile.userId;
    userService
      .httpApi("GET", `admin/users/${_id}`, null)
      .then((res) => {
        history.push({
          pathname: "/users/view",
          search: "",
          state: { details: res },
        });
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className={styles.HeaderComponent}>
      <Navbar collapseOnSelect expand="lg" className={styles.SiteHeaderColor}>
        <Container>
          <Navbar.Brand
            className="d-flex"
            href={userType !== "Crew" ? "teams" : "projects"}
          >
            <img
              alt=""
              src={logo2}
              height="100"
              className="logo-2 d-inline-block align-top"
            />
            <span className="brand-name d-flex align-items-center justify-content-center">
              MAP APP
            </span>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="p-3 justify-content-end">
            <Nav activeKey={"/" + activePath[1]}>
              {isLogged.logedIn && StorageService.getLogin() !== null ? (
                <>
                  {/* <Nav.Item>
                                    <Nav.Link as={Link} eventKey={1} to="/home">Dashboard</Nav.Link>
                                </Nav.Item> */}
                  {userType === "Admin" || userType === "Manager" ? (
                    <>
                      <Nav.Item>
                        <Nav.Link as={Link} eventKey={"/teams"} to="/teams">
                          Teams
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          eventKey={"/projects"}
                          to="/projects"
                        >
                          Projects
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} eventKey={"/maps"} to="/maps">
                          Maps
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} eventKey={"/users"} to="/users">
                          Users
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  ) : (
                    <Nav.Item>
                      <Nav.Link as={Link} eventKey={"/projects"} to="/projects">
                        Projects
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <NavDropdown
                    title={userInfo.userType}
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item onClick={() => handleView()}>
                      Profile ({userInfo.userName})
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                    <NavDropdown.Item>Something else here</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                ""
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
