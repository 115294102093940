import React from "react";
import { Navbar } from "react-bootstrap";

function Footer(props) {
  let appUrl = window.location.origin.toString();
  return (
    <div className="footer-outer">
      <Navbar variant="dark" className="footer">
        <p>
          MAP APP -{" "}
          <a href={appUrl} title={appUrl}>
            <small>{appUrl}</small>
          </a>
        </p>
      </Navbar>
    </div>
  );
}

export default Footer;
