import { LOGIN, LOGOUT, SIGN_UP } from "../constants";

export const login = (data) => {
  return {
    data: data,
    type: LOGIN,
  };
};
export const signup = (data) => {
  return {
    data: data,
    type: SIGN_UP,
  };
};
export const logout = () => {
  return {
    data: false,
    type: LOGOUT,
  };
};

export const changeColor = (data) => {
  return {
    data: data,
    type: data.type,
  };
};
