import { connect } from "react-redux";
import Login from "../components/LoginComponent/LoginComponent";
import { login } from "../Services/Actions/actions";

const mapStateToProps = (state) => ({
  loginData: state.login,
  userType: state.userType,
});
const mapDispatchToProps = (dispatch) => ({
  userLogin: (data) => dispatch(login(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
