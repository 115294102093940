import React, { useEffect, useState } from 'react';
import { Container, Button, Col, Row, Table } from 'react-bootstrap';
import styles from './TeamsComponent.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';

const TeamManagersComponent = (props) => {
    const [userData, setUserData] = useState([])

    useEffect(() => {
        setUserData(props.location.state.details)
    }, [props, userData])


    return (
        <div className={styles.TeamsComponent} >
            <Container>
                <Row>
                    <Col className="p-3 mt-5 form-outer">
                        <Row className="border-bottom">
                            <Col>
                                <div className="from-heading">
                                    <h2>Team Manager</h2>
                                </div>
                            </Col>
                            <Col>
                                <Button
                                    className="float-right"
                                    as={Link}
                                    to="/teams"
                                    variant="primary"
                                >
                                    <ArrowBackIosIcon />
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        <Table className="mt-3 table-responsive-lg" striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Manager Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userData.map((manager, i) => {
                                    return <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td className={'text-capitalize'}>{manager.firstName + ' ' + manager.lastName}</td>
                                        <td>{manager.email}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>

                    </Col>
                </Row>
            </Container>
        </div >
    );
}

export default TeamManagersComponent;