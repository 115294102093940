import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import styles from "./MapsComponent.module.css";
import { userService } from "../../Services/userServices";
import { DeleteForever, Edit, RemoveRedEye, Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import MyPagination from "../../Services/pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Image from "react-bootstrap/Image";
import apiUrl from "../../constants";
import insane from "insane";
import StorageService from "../../storage/StorageService";
import { Tables } from "../Helpers/Tables";

const MapList = (props) => {
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [key, setKey] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [overLay, setOverLay] = useState(false);
  const [table, setTable] = useState("");
  const [tableHead] = useState([
    "#",
    "Map Logo",
    "Map Title",
    "Location",
    "Sign Label",
    "Contact",
    "Notes",
    "Status",
    "Actions",
  ]);
  const [icons] = useState({
    Edit: <Edit />,
    View: <RemoveRedEye />,
    Delete: <DeleteForever />,
  });
  const [buttonColor] = useState({
    Edit: "primary",
    View: "success",
    Delete: "danger",
  });

  const handleView = useCallback(
    (_id) => {
      setOverLay(userService.overLay(true));
      props.history.push({
        pathname: "/maps/view",
        search: "",
        state: { mapId: _id },
      });
    },
    [props.history]
  );

  const handleEdit = useCallback(
    (_id) => {
      setOverLay(userService.overLay(true));
      props.history.push({
        pathname: "/maps/edit",
        search: "",
        state: { mapId: _id },
      });
    },
    [props.history]
  );

  const drawTable = useCallback(
    (map, i) => {
      return {
        sno: (currPage - 1) * perPage + i + 1,
        mapLogo: (
          <Image src={apiUrl + map.mapLogo} fluid thumbnail width="80" />
        ),
        mapTitle: map.mapTitle,
        location: map.location,
        signLabel: map.signLabel,
        contact: map.contactInfo,
        notes: (
          <div className="App">
            <Sanitized html={map.notes} />
          </div>
        ),
        status: map.status === false ? "Inactive" : "Active",
        actions: ["Edit", "View", "Delete"].map((res, i) => {
          return Tables.tableButton(
            {
              name: res,
              color: buttonColor[res],
              size: "sm",
              index: i,
              icon: icons[res],
            },
            function () {
              switch (res) {
                case "Edit":
                  return handleEdit(map._id);

                case "View":
                  return handleView(map._id);

                case "Delete":
                  return handleDelete(map._id);

                default:
                  break;
              }
            }
          );
        }),
      };
    },
    [buttonColor, currPage, handleEdit, handleView, icons, perPage]
  );

  const getMaps = useCallback(
    async (page) => {
      setCurrPage(page);
      await userService
        .httpApi("GET", `admin/maps/${page}/${perPage}`, null)
        .then((res) => {
          const data = res.maps.map((resp, i) => drawTable(resp, i));
          setTable(Tables.drawTable(tableHead, data));
          setTotalPage(Math.ceil(res.totalCount / perPage));
        })
        .catch((error) => {
          if (error === "Token Expired") {
            toast.warn(error);
            StorageService.clearLogin();
          } else if (error === "Login Session Expired") {
            toast.warn(error);
            StorageService.clearLogin();
            props.history.push(`/login`);
          } else {
            toast.warn(error);
          }
        });
      setSpinner(false);
      setOverLay(userService.overLay(false));
    },
    [drawTable, perPage, props.history, tableHead]
  );

  useEffect(() => {
    document.title = "Map App - Maps";
    setOverLay(userService.overLay(true));
    setSpinner(true);
    getMaps(currPage);
  }, [perPage, key, getMaps, currPage]);

  const perPageCount = (e) => {
    setCurrPage(1);
    setPerPage(e.target.value);
  };

  const handleDelete = (_id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        html: "You will not be able to recover this map",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then(async (result) => {
        if (result.value) {
          await userService.httpApi("DELETE", `admin/map/${_id}`, null);
          setKey(Math.random());
          Swal.fire("Deleted!", "Map has been deleted.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Map is safe &#128526;", "error");
        }
      });
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const Sanitized = ({ html }) => (
    <>
      <div
        className="chopline"
        dangerouslySetInnerHTML={{
          __html: insane(html, {
            allowedTags: [
              "a",
              "article",
              "b",
              "blockquote",
              "br",
              "caption",
              "code",
              "del",
              "details",
              "div",
              "em",
              "h1",
              "h2",
              "h3",
              "h4",
              "h5",
              "h6",
              "hr",
              "i",
              "img",
              "ins",
              "kbd",
              "li",
              "main",
              "ol",
              "p",
              "pre",
              "section",
              "span",
              "strike",
              "strong",
              "sub",
              "summary",
              "sup",
              "table",
              "tbody",
              "td",
              "th",
              "thead",
              "tr",
              "u",
              "ul",
            ],
          }),
        }}
      />
      {html.length > 50 && (
        <span>
          <b>...</b>
        </span>
      )}
    </>
  );

  return (
    <div className={styles.MapsComponent}>
      {overLay}
      <Container>
        <Row>
          <Col className="p-3 mb-5 mt-5 form-outer">
            <Row className="border-bottom">
              <Col>
                <div className="from-heading">
                  <h2>All Maps</h2>
                </div>
              </Col>
              <Col>
                <Button
                  className="float-right"
                  as={Link}
                  to="/maps/add"
                  variant="primary"
                >
                  <Add />
                  Add Map
                </Button>
              </Col>
            </Row>
            {spinner ? "Loading..." : table}
            <Row>
              <Col>
                <Form.Control
                  className="w-auto"
                  name="userType"
                  as="select"
                  value={perPage || ""}
                  onChange={perPageCount}
                  style={{ marginTop: "15px" }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Form.Control>
              </Col>
              <Col>
                <MyPagination
                  totPages={totalPage}
                  currentPage={currPage}
                  pageClicked={(e) => getMaps(e)}
                ></MyPagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MapList;
