import StorageService from './../storage/StorageService';
import apiUrl from './../constants';
import OverLay from '../components/Layout/OverLay';

export const userService = { login, logout, httpApi, signup, getUser, overLay };

async function login(data) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return await fetch(`${apiUrl}login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            StorageService.setLogin(user);
            return user;
        });
}

async function getUser(_id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${StorageService.getLogin().token}` },
        body: null
    };
    return await fetch(`${apiUrl}admin/users/${_id}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            user.userId = user._id
            const data = StorageService.getLogin()
            data.profile = user
            StorageService.setLogin(data);
            return user;
        });
}

async function signup(data, id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return await fetch(`${apiUrl}signup/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function logout() {
    StorageService.clearLogin();
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 404) {
                response.statusText = 'not found';
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

async function httpApi(methodType, url, bodyData, contentType = null) {
    const requestOptions = {
        method: methodType,
        headers: contentType !== null ? { 'Accept': 'application/json', 'Authorization': `Bearer ${StorageService.getLogin().token}` } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${StorageService.getLogin().token}` },
        body: bodyData
    };
    return await fetch(`${apiUrl + url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function overLay(input) {
    return <OverLay overLay={input} />
}