import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import styles from "./MapsComponent.module.css";
import StorageService from "../../storage/StorageService";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Image from "react-bootstrap/Image";
import { userService } from "../../Services/userServices";

const MapAddComponent = (props) => {
  const { location } = props;
  const initialValues = {
    // type all the fields you need
    mapTitle: "",
    location: "",
    signLabel: "",
    contactInfo: "",
    notes: "",
    status: false,
  };

  const [fieldsData, setValues] = useState(initialValues);
  const [errors, setErrorValues] = useState(initialValues);
  const [notes, setNotes] = useState("");
  const [locationM, setLocationM] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [mapLogo, setMapLogo] = useState(null);
  const [preview, setPreview] = useState({});
  const [allowedExtn] = useState(["png", "jpg", "jpeg", "gif"]);
  const [otherLocation] = useState([]);

  useEffect(() => {
    var input = document.getElementById("auto-place-add");
    var autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ["geocode"],
    });
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        toast.error("No details available for input: " + place.name);

        return;
      }
      setLocationM(place.formatted_address);
    });
  }, []);

  const handleValidation = () => {
    let fields = fieldsData;
    let errors = {};
    let formIsValid = true;

    if (!fields["mapTitle"]) {
      formIsValid = false;
      errors["mapTitle"] = "Map Title cannot be empty";
    }

    if (!fields["contactInfo"]) {
      formIsValid = false;
      errors["contactInfo"] = "Contact cannot be empty";
    }

    if (!fields["signLabel"]) {
      formIsValid = false;
      errors["signLabel"] = "Sign label cannot be empty";
    }

    if (!notes) {
      formIsValid = false;
      errors["notes"] = "Notes cannot be empty";
    }

    if (!location) {
      formIsValid = false;
      errors["location"] = "Location cannot be empty";
    }

    setFormIsValid(formIsValid);
    setErrorValues(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    setValues({ ...fieldsData, [e.target.name]: e.target.value });
  };

  const onChangeFileHandler = (event) => {
    const extn = event.target.files[0].name.split(".").pop().toLowerCase();
    if (~allowedExtn.indexOf(extn)) {
      setMapLogo(event.target.files[0]);
      const objectUrl = URL.createObjectURL(event.target.files[0]);
      setPreview(objectUrl);
    } else {
      toast.warn("Invalid File Format.");
    }
  };

  const handleMapInput = async (event) => {
    event.preventDefault();
    try {
      if (handleValidation()) {
        const data = new FormData();
        data.append("mapTitle", fieldsData.mapTitle);
        data.append("mapLogo", mapLogo);
        data.append("location", locationM);
        data.append("contactInfo", fieldsData.contactInfo);
        data.append("signLabel", fieldsData.signLabel);
        data.append("status", fieldsData.status);
        data.append("notes", notes);
        data.append("createdBy", StorageService.getLogin().profile.userId);
        data.append("otherLocation", JSON.stringify(otherLocation));
        userService
          .httpApi("POST", `admin/maps`, data, "Accept")
          .then((res) => {
            toast.success(res.message);
            setTimeout(() => {
              props.history.push({
                pathname: "/maps/edit",
                search: "",
                state: { mapId: res.mapId },
              });
            }, 800);
          })
          .catch(function (error) {
            console.log(error);
            toast.warn(error);
          });
      }
    } catch (error) {
      toast.warn(error);
    }
  };

  return (
    <div className={styles.MapsComponent}>
      <Container>
        <Row>
          <Col className="p-3 mt-5 form-outer">
            <Row className="border-bottom">
              <Col>
                <div className="from-heading">
                  <h2>Add Map Details</h2>
                </div>
              </Col>
              <Col>
                <Button
                  className="float-right"
                  as={Link}
                  to="/maps"
                  variant="primary"
                >
                  <ArrowBackIos />
                  Back
                </Button>
              </Col>
            </Row>
            <Form
              className="mt-3"
              noValidate
              validated={formIsValid}
              onSubmit={handleMapInput}
            >
              {/* <Row className='border border-dark'>
                                <Col className={styles.setMapBox}>

                                </Col>
                            </Row> */}
              <Row className={styles.mainMapBox}>
                <Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicFName">
                      <Form.Label>Map Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Map Title"
                        name="mapTitle"
                        value={fieldsData.mapTitle || ""}
                        isInvalid={!!errors.mapTitle}
                        onChange={handleChange}
                      />
                      {errors.mapTitle && (
                        <Form.Text className="text-danger">
                          {errors.mapTitle}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="file">
                      <Form.Label>Map Logo</Form.Label>
                      <Form.Control
                        type="file"
                        name="file"
                        onChange={onChangeFileHandler}
                      />
                      {mapLogo && (
                        <Image src={preview} fluid thumbnail width="80" />
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        type="text"
                        id="auto-place-add"
                        placeholder="Location"
                        name="location"
                        value={locationM || ""}
                        isInvalid={!!errors.location}
                        onChange={(e) => setLocationM(e.target.value)}
                      />
                      {errors.location && (
                        <Form.Text className="text-danger">
                          {errors.location}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicFName">
                      <Form.Label>Sign Label</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sign Label"
                        name="signLabel"
                        value={fieldsData.signLabel || ""}
                        isInvalid={!!errors.signLabel}
                        onChange={handleChange}
                      />
                      {errors.signLabel && (
                        <Form.Text className="text-danger">
                          {errors.signLabel}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicFName">
                      <Form.Label>Contact</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Contact"
                        name="contactInfo"
                        value={fieldsData.contactInfo || ""}
                        isInvalid={!!errors.contactInfo}
                        onChange={handleChange}
                      />
                      {errors.contactInfo && (
                        <Form.Text className="text-danger">
                          {errors.contactInfo}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formGridNotes">
                      <Form.Label>Notes Section</Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "Heading",
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                            "BlockQuote",
                            "List",
                            "Indent",
                            "Table",
                          ],
                        }}
                        data={notes}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setNotes(data);
                        }}
                      />
                      {errors.notes && (
                        <Form.Text className="text-danger">
                          {errors.notes}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formGridStatus">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        name="status"
                        as="select"
                        value={fieldsData.status}
                        onChange={handleChange}
                      >
                        <option value="">Select..</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MapAddComponent;
