import StorageService from './../../storage/StorageService';

export function isLogged(state = {}) {
    return {
        logedIn: (StorageService.getLogin() === null) ? false : true
    };
}

export function userType(state = {}) {
    if (StorageService.getLogin() && StorageService.getLogin() !== null) {
        return StorageService.getLogin().profile.userType;
    } 
    return ''
}