import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import styles from "./ProjectsComponent.module.css";
import { userService } from "../../Services/userServices";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import MyPagination from "../../Services/pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import StorageService from "../../storage/StorageService";
import { connect } from "react-redux";
import { Tables } from "../Helpers/Tables";

const ProjectsComponent = (props) => {
  const { userType } = props;
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [key, setKey] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [overLay, setOverLay] = useState(false);
  const [table, setTable] = useState("");
  const [tableHeadAdmin] = useState(["#", "Project Name", "Status", "Actions"]);
  const [tableHeadCrew] = useState(["#", "Project Name", "Team Name", "Maps"]);

  const [icons] = useState({
    Edit: <EditIcon />,
    Delete: <DeleteForeverIcon />,
  });
  const [buttonColor] = useState({
    Edit: "primary",
    Delete: "danger",
  });

  const teamMaps = useCallback(
    (_id) => {
      userService
        .httpApi("GET", `team/maps/${_id}`, null)
        .then((res) => {
          props.history.push({
            pathname: "/teams/maps",
            search: "",
            state: { details: res },
          });
        })
        .catch((err) => console.log(err));
    },
    [props.history]
  );

  const handleEdit = useCallback(
    (_id) => {
      userService
        .httpApi("GET", `admin/projects/${_id}`, null)
        .then((res) => {
          props.history.push({
            pathname: "/projects/edit",
            search: "",
            state: { details: res },
          });
        })
        .catch((err) => console.log(err));
    },
    [props.history]
  );

  const drawTable = useCallback(
    (resp, i, x) => {
      if (userType !== "Crew") {
        return {
          sno: (currPage - 1) * perPage + i + 1,
          projectName: resp.projectName,
          status: resp.status ? "Active" : "Inactive",
          actions: ["Edit", "Delete"].map((res, i) => {
            return Tables.tableButton(
              {
                name: res,
                color: buttonColor[res],
                size: "sm",
                index: i,
                icon: icons[res],
              },
              function () {
                switch (res) {
                  case "Edit":
                    return handleEdit(resp._id);

                  case "Delete":
                    return handleDelete(resp._id);

                  default:
                    break;
                }
              }
            );
          }),
        };
      } else {
        return {
          sno: (currPage - 1) * perPage + i + 1,
          projectName: resp.projectName,
          teamName: x.teamName,
          actions: Tables.tableButton(
            { name: "View", color: "success", size: "sm" },
            function () {
              return teamMaps(x.teamId);
            }
          ),
        };
      }
    },
    [buttonColor, currPage, handleEdit, icons, perPage, teamMaps, userType]
  );

  const getProjects = useCallback(
    async (page) => {
      setCurrPage(page);
      if (userType === "Admin" || userType === "Manager") {
        await userService
          .httpApi("GET", `admin/projects/${page}/${perPage}`, null)
          .then((res) => {
            const data = res.projects.map((y, i) => drawTable(y, i, y));
            setTable(Tables.drawTable(tableHeadAdmin, data));
            setTotalPage(Math.ceil(res.totalCount / perPage));
          })
          .catch((error) => {
            if (error === "Token Expired") {
              toast.warn(error);
              StorageService.clearLogin();
            } else if (error === "Login Session Expired") {
              toast.warn(error);
              StorageService.clearLogin();
              props.history.push(`/login`);
            } else {
              toast.warn(error);
            }
          });
      } else {
        await userService
          .httpApi(
            "GET",
            `user/projects/${
              StorageService.getLogin().profile.userId
            }/${page}/${perPage}`,
            null
          )
          .then((res) => {
            let data;
            res.projects.map(
              (x) => (data = x.projects.map((y, i) => drawTable(y, i, x)))
            );
            setTable(Tables.drawTable(tableHeadCrew, data));
            setTotalPage(Math.ceil(res.totalCount / perPage));
          })
          .catch((error) => {
            if (error === "Token Expired") {
              toast.warn(error);
              StorageService.clearLogin();
            } else if (error === "Login Session Expired") {
              toast.warn(error);
              props.history.push(`/login`);
              StorageService.clearLogin();
            } else {
              toast.warn(error);
            }
          });
      }
      setOverLay(userService.overLay(false));
      setSpinner(false);
    },
    [userType, perPage, tableHeadAdmin, drawTable, props.history, tableHeadCrew]
  );

  useEffect(() => {
    document.title = "Map App - Projects";
    setSpinner(true);
    setOverLay(userService.overLay(true));
    getProjects(currPage);
  }, [perPage, key, getProjects, currPage]);

  const perPageCount = (e) => {
    setCurrPage(1);
    setPerPage(e.target.value);
  };

  const handleDelete = (_id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        html: "You will not be able to recover this project",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then(async (result) => {
        if (result.value) {
          await userService.httpApi("DELETE", `admin/projects/${_id}`, null);
          setKey(Math.random());
          Swal.fire("Deleted!", "Project has been deleted.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Project is safe &#128526;", "error");
        }
      });
    } catch (error) {
      toast.warn(error.message);
    }
  };

  return (
    <div className={styles.UsersComponent}>
      {overLay}
      <Container>
        <Row>
          <Col className="p-3 mb-5 mt-5 form-outer">
            <Row className="border-bottom">
              <Col>
                <div className="from-heading">
                  <h2>Projects</h2>
                </div>
              </Col>
              {userType !== "Crew" && (
                <Col>
                  <Button
                    className="float-right"
                    as={Link}
                    to="/projects/add"
                    variant="primary"
                  >
                    <AddIcon />
                    Add Project
                  </Button>
                </Col>
              )}
            </Row>
            {spinner ? "Loading..." : table}
            <Row>
              <Col>
                <Form.Control
                  className="w-auto"
                  name="pager"
                  as="select"
                  value={perPage || ""}
                  onChange={perPageCount}
                  style={{ marginTop: "15px" }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Form.Control>
              </Col>
              <Col>
                <MyPagination
                  totPages={totalPage}
                  currentPage={currPage}
                  pageClicked={(e) => getProjects(e)}
                ></MyPagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userType: state.userType,
  };
};

export default connect(mapStateToProps)(ProjectsComponent);
