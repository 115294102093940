import React, { Component } from "react";

class Map extends Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this);
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );
    this.props.onMapLoad(map);
  }

  componentDidMount() {
    this.onScriptLoad();
  }

  render() {
    return (
      <div
        style={{ height: this.props.height, width: "100%" }}
        id={this.props.id}
      />
    );
  }
}

export default Map;
